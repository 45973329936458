.spotTrading {
  // margin-top: 30px;
  padding: 20px;
  background-color: #111114;

  &__back {
    margin-bottom: 30px;
  }

  &__bal {
    display: grid;
    grid-template-columns: 3fr 2fr 2fr;
    gap: 20px;
    margin-bottom: 30px;

    @media screen and (max-width: 800px) {
      grid-template-columns: repeat(2, 1fr);
    }
    @media screen and (max-width: 550px) {
      grid-template-columns: 1fr;
    }

    &__value {
      border-radius: 13px;
      background: #000;
      padding: 20px;

      &__header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 50px;
        flex-wrap: wrap;
        gap: 10px;

        &__right {
          display: flex;
          align-items: center;
          gap: 10px;
          flex-wrap: wrap;

          & span {
            font-size: 12px;
            font-style: normal;
            font-weight: 700;
            padding: 3px 11px;
            display: inline-block;
            border-radius: 6px;

            &.active {
              background: linear-gradient(315deg, #9475d4 0%, #30f 100%);
            }
          }
        }
      }

      &__bottom {
        & h4 {
          color: #fffdd0;
          font-size: 14px;
          font-weight: 700;
          margin-bottom: 5px;
        }

        & p {
          font-size: 27px;
          font-style: normal;
          font-weight: 700;
          margin: 0;
        }
      }
    }

    &__today {
      border-radius: 13px;
      background: #000;
      padding: 20px;

      &__header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 50px;

        & p {
          margin: 0;
          color: #58bd7d;
          font-size: 15px;
          font-weight: 700;
        }
      }

      &__bottom {
        & h4 {
          color: #fffdd0;
          font-size: 14px;
          font-weight: 700;
          margin-bottom: 5px;
        }

        & p {
          font-size: 27px;
          font-style: normal;
          font-weight: 700;
          margin: 0;
        }
      }
    }
  }

  &__btn {
    display: flex;
    justify-content: center;
    margin-bottom: 30px;
  }

  &__list {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 20px;

    @media screen and (max-width: 800px) {
      grid-template-columns: repeat(2, 1fr);
    }
    @media screen and (max-width: 550px) {
      grid-template-columns: 1fr;
    }

    &__item {
      padding: 20px;
      background-color: #000;
      border-radius: 16px;
      cursor: pointer;

      &__token {
        display: flex;
        gap: 20px;
        justify-content: space-between;
        margin-bottom: 10px;
        flex-wrap: wrap;

        &__left {
          display: flex;
          gap: 10px;

          & h4 {
            font-size: 18px;
          }

          & p {
            font-size: 14px;
          }

          & img {
            width: 51.5px;
          }
        }

        &__right {
          & h4 {
            font-size: 24px;
          }
          & p {
            color: #40bf6a;
            font-size: 14px;
          }
        }
      }

      &__amo {
        font-size: 14px;
        margin-top: 10px;
        color: #b1b5c3;
        & span {
          color: #fff;
          font-weight: 600;
        }

        & p {
          margin-bottom: 0;
        }
      }
    }
  }

  &__amount {
    background-color: #000;
    padding: 20px;

    &__search {
      position: relative;

      & input {
        border-radius: 30px;
        background: #111114;
        height: 51px;
        width: 100%;
        border: none;
        margin: 20px 0;
        color: #fff;
        padding: 0 20px;
        padding-left: 70px;
      }

      & img {
        position: absolute;
        left: 30px;
        top: 50%;
        transform: translateY(-50%);
      }
    }

    &__list {
      &__item {
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 20px;
        flex-wrap: wrap;
        margin-bottom: 20px;

        &__left {
          display: flex;
          align-items: flex-start;
          gap: 10px;

          & h5 {
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
          }
        }

        &__right {
          & h5 {
            font-size: 16px;
            font-weight: 700;
          }

          & p {
            font-size: 13px;
            font-weight: 400;
          }
        }
      }
    }
  }

  &__trade {
    &__header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 30px;

      &__left {
        display: flex;
        align-items: center;
        gap: 10px;
      }
    }

    &__btn {
      display: grid;
      grid-template-columns: 1fr 1fr;
      margin: 0 20px;
      margin-bottom: 30px;

      &__item {
        display: block;
        // border-radius: 14px;
        background: #000;
        height: 80px;
        font-size: 34px;
        font-weight: 700;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;

        &.sell {
          border-radius: 0 13px 13px 0;
        }
        &.buy {
          border-radius: 13px 0 0 13px;
        }

        &.activeBuy {
          background-color: #40bf6a;
        }
        &.activeSell {
          background-color: #ff2000;
        }

        @media screen and (max-width: 650px) {
          font-size: 24px;
          height: 60px;
        }
      }
    }

    &__main {
      margin: 0 20px;
      border-radius: 14px;
      background: rgba(232, 232, 232, 0.08);
      background-blend-mode: color-dodge;
      padding: 20px;

      @media screen and (max-width: 650px) {
        margin: 0;
      }

      & select {
        border-radius: 14px;
        background: #111114;
        padding: 0 5%;
        width: 100%;
        height: 158px;
        font-size: 36px;
        font-weight: 600;
        color: #f0f1ff;
        border: none;
        margin-bottom: 20px;

        @media screen and (max-width: 650px) {
          font-size: 24px !important;
          height: 100px !important;
        }
      }

      &__price {
        border-radius: 14px;
        background: #111114;
        padding: 0 5%;
        width: 100%;
        height: 158px;
        font-size: 36px;
        font-weight: 600;
        color: #f0f1ff;
        margin-bottom: 20px;
        display: flex;
        align-items: center;
        justify-content: space-between;

        @media screen and (max-width: 650px) {
          font-size: 24px;
          height: 100px;
        }

        &__right {
          & span {
            font-weight: 300;
            cursor: pointer;
          }

          & input {
            background-color: transparent;
            font-size: 36px;
            font-weight: 600;
            color: #f0f1ff;
            border: none;
            display: inline-block;
            width: 180px;
            text-align: center;

            @media screen and (max-width: 650px) {
              font-size: 24px;
              width: 100px;
            }

            &:focus {
              outline: none;
              border: none;
            }

            &::-webkit-outer-spin-button,
            &::-webkit-inner-spin-button {
              -webkit-appearance: none;
              margin: 0;
            }

            /* Firefox */
            &[type="number"] {
              -moz-appearance: textfield;
            }
          }
        }
      }

      & select {
        border-radius: 14px;
        background: #111114;
        padding: 0 5%;
        width: 100%;
        height: 158px;
        font-size: 36px;
        font-weight: 600;
        color: #f0f1ff;
        border: none;
        margin-bottom: 20px;
      }

      &__btc {
        border-radius: 14px;
        background: #111114;
        padding: 0 5%;
        width: 100%;
        height: 158px;
        font-size: 36px;
        font-weight: 400;
        color: #f0f1ff;
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 20px;
        @media screen and (max-width: 650px) {
          font-size: 24px;
          height: 100px;
        }

        &__left {
          display: flex;
          align-items: center;
          gap: 10px;
        }
      }

      &__avail {
        font-size: 18px;
        font-weight: 500;
        margin-bottom: 30px;
      }
    }
  }

  &__asset {
    &__btn {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 30px;
      margin-bottom: 20px;
      flex-wrap: wrap;
    }

    &__market {
      display: grid;
      grid-template-columns: 1fr 2fr;
      gap: 20px;

      @media screen and (max-width: 850px) {
        grid-template-columns: 1fr;
      }
      &__table {
        overflow: auto;
        // padding-bottom: 40px;
        // padding-top: 40px;

        &__body {
          display: flex;
          flex-direction: column;
          gap: 8px;
          min-width: 150px;

          &.big {
            min-width: 950px;
          }
        }

        &__row {
          gap: 10px;
          padding: 10px;
          display: flex;
          align-items: center;
          height: 25px;
          //   @include themed() {
          //     background-color: t("bgAlt");
          //   }

          &__head {
            font-size: 12px;
          }

          &__data {
            font-weight: 400;
            font-size: 12px;

            // @include themed() {
            //   color: t("textAlt");
            // }

            &.buy {
              color: #58bd7d;
            }
          }
        }
      }
    }

    &__trans {
      & h3 {
        padding: 16px 25px;
        margin: 20px 0;
        background: #000;
      }

      &__main {
        padding: 20px;
        background: #000;
      }

      &__header {
        display: flex;
        align-items: center;
        gap: 20px;
        margin-bottom: 20px;

        & span {
          font-size: 14px;
          cursor: pointer;

          &.active {
            color: #58bd7d;
          }
        }
      }
    }
  }
}
