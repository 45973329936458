.loan__repay {
  &__title {
    color: #fff;
    font-size: 24px;
    font-style: normal;
    font-weight: 800;

    line-height: normal;
  }

  &__modal {
    width: 844px;
    height: auto;
    border-radius: 12px;
    background: #000;
    display: flex;
    margin: 20px auto;
    padding: 40px 5em;
    align-items: center;
    flex-direction: column;
    &__steps {
      width: 80%;
      display: flex;
      align-items: center;
      justify-content: space-evenly;
      img {
        width: 5%;
        height: 5%;
      }
      &__form {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-evenly;
      }
      &__p {
        width: 40px;
        height: 40px;
        display: flex;
        border-radius: 100px;
        align-items: center;
        color: #fff;
        justify-content: center;
        background: linear-gradient(
          90deg,
          #c9b2f9 0%,
          rgba(51, 0, 255, 0.99) 100%
        );
      }
      &__pp {
        width: 40px;
        height: 40px;
        display: flex;
        border-radius: 100px;
        align-items: center;
        color: white;
        justify-content: center;
        background: #d7d7d7;
      }
    }
    h1 {
      color: #fff;
      font-size: 32px;
      font-style: normal;
      font-weight: 700;
      margin: 1em 0;
      line-height: 40px; /* 125% */
    }
    P {
      color: #b1b5c3;
      font-size: 15px;
      font-style: normal;
      margin: 3em 0;
      font-weight: 600;
      line-height: 20px; /* 133.333% */
    }
  }
}

  .modalMain .getLoan__modal .cancel-flex {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .modalMain .getLoan__modal .cancel {
    text-align: center;
    font-family: Kanit;
    font-size: 15px;
    font-style: normal;
    font-weight: 500;
    line-height: 16px; /* 106.667% */
    background: linear-gradient(90deg, #c9b2f9 0%, rgba(51, 0, 255, 0.99) 100%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }

   .modalMain p{
    color: var(--neutrals-5, #B1B5C3);
text-align: center;
font-family: Kanit;
font-size: 15px;
font-style: normal;
font-weight: 400;
line-height: 24px; /* 160% */
  }

  .modalMain button{
    // width: 100% !important;
    // padding: 20px;
    width: 200px;
  }