.quickSellCenter {
  background: #111114;
  padding: 30px 20px;

  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 20px;
    flex-wrap: wrap;
    margin-bottom: 40px;

    & h2 {
      font-size: 24px;
      font-weight: 800;
    }
  }

  &__main {
    &__center {
      background-image: url(../../assets/qbcBg.png);
      background-position: center;
      padding: 20px;
      min-height: 192px;
      border-radius: 16px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      color: #020202;
      margin-bottom: 20px;

      & h2 {
        font-size: 32px;
        font-weight: 700;
      }

      & p {
        max-width: 563px;
      }
    }

    &__subTitle {
      font-size: 24px;
      font-weight: 700;
      margin-bottom: 40px;
    }

    &__option {
      margin-bottom: 40px;

      &.active {
        & p {
          border: 1px solid transparent;
          background: linear-gradient(black, black) padding-box,
            linear-gradient(
                94.96deg,
                #5158f6 0.8%,
                #822df5 52.98%,
                #f3a761 100%
              )
              border-box;
          background-color: transparent;
        }
      }
      & h4 {
        text-align: center;
        font-size: 20px;
        font-weight: 600;
        margin-bottom: 20px;
      }

      & p {
        background: #000;
        padding: 30px;
        color: rgba(255, 255, 255, 0.7);
      }
    }

    &__btn {
      display: flex;
      justify-content: center;
      margin-top: 50px;
    }
  }
}
