@font-face {
  font-family: kanit;
  src: url(../../shared/utils/fonts/Kanit-Regular.ttf);
}
.main {
  overflow: hidden;
  background: #000000;
  // width: 100%;
  @media (min-width: 1500px) {
    background: transparent;
  }
}

.btn {
  display: flex;
  width: 275px;
  height: 56px;
  padding: 10px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  flex-shrink: 0;
  border-radius: 8px;
  border: none;
  outline: none;
  color: #fff;
  background: var(
    --new-1,
    linear-gradient(132deg, #5158f6 0%, #822df5 52.6%, #f3a761 100%)
  );
}

.mainHero {
  background: #161322;
  width: 100%;
  height: 100%;
  margin: 0 auto;

  @media (min-width: 1500px) {
    width: 100%;
    height: 100%;
    padding: 0 24em;
  }
  @media (max-width: 468px) {
    height: 100%;
  }
  .imgHero {
    width: 100%;
    margin-left: -3em;
    margin-top: 3.54em;
    margin-bottom: -2.8em;

    @media (min-width: 1500px) {
      display: none;
    }
    @media (max-width: 768px) {
      display: none;
    }
  }
}

.mainHeroContent {
  padding: 3em;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  color: #fff;

  button,
  p,
  h3 {
    margin: 0.7em 0;
    @media (min-width: 1500px) {
      margin: 1.3em 0;
    }
  }
}
.cardContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  width: 80%;
  @media (max-width: 1028px) {
    width: 90%;
  }
  @media (min-width: 1500px) {
    width: 50%;
  }
}
.card {
  width: 100%;
  height: 489px;
  border-radius: 16px;
  display: flex;
  margin: 3em auto;
  height: auto;
  z-index: 1000;
  @media (min-width: 1500px) {
    height: auto;
  }
  @media (max-width: 768px) {
    display: flex;
    flex-direction: column;
  }
}

.backz {
  position: absolute;
  top: 21%;
  left: 3%;
  cursor: pointer;
  z-index: 1000;
  @media (min-width: 1500px) {
    top: 11%;
  }
  @media (max-width: 768px) {
    top: 10%;
  }
  @media (max-width: 460px) {
    top: 13%;
  }
}
.backk {
  position: absolute;
  top: 21%;
  left: 3%;
  cursor: pointer;
  z-index: 1000;
  @media (min-width: 1500px) {
    top: 8%;
  }
  @media (max-width: 768px) {
    top: 13%;
  }
  @media (max-width: 460px) {
    top: 12%;
  }
}

.cardContent {
  width: 50%;
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  border-top-left-radius: 16px;
  border-bottom-left-radius: 16px;

  background: linear-gradient(
    90deg,
    #b88717 0%,
    #ffd166 8.48%,
    #f5d281 27.91%,
    #6b5011 63.23%,
    #6b5011 85.01%,
    #c48e0e 100%
  );
  @media (max-width: 768px) {
    width: 100%;
    border-top-left-radius: 16px;
    border-top-right-radius: 16px;
    border-bottom-left-radius: 0px;
  }
  h2 {
    color: #333;
    font-size: 32px;
    font-weight: 700;
  }
  p {
    color: #333;
    font-size: 16px;
    font-weight: 500;
    line-height: 28px; /* 175% */
  }
}

.cardBtn {
  display: flex;
  align-items: center;
  justify-content: space-between;
  img {
    @media (max-width: 468px) {
      display: none;
    }
  }
}

.pageTitle {
  position: relative;
  h1 {
    @media (max-width: 768px) {
      margin: 0.6em 0;
    }
  }

  h2 {
    color: #fff;
    font-size: 32px;
    font-style: normal;
    font-weight: 700;
    position: absolute;
    line-height: normal;
    letter-spacing: 0.96px;
  }
}

.lite {
  background: #0e0b13;
  width: 100%;
  height: 100%;
  padding: 3em 7em;
  margin: 0 auto;

  @media (min-width: 1500px) {
    height: 100vh;
  }
  h2 {
    color: #fff;
    font-size: 32px;
    font-weight: 900;
    letter-spacing: 0.96px;
    margin-top: 1.5em;
    @media (max-width: 468px) {
      font-size: 22px;
    }
  }
  h3 {
    color: #fff;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: 34px; /* 170% */
  }
  @media (max-width: 468px) {
    padding: 2em;
  }
}

.liteContent {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 20px;
  width: 100%;
  p {
    color: #fff;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px; /* 183.333% */
  }
  span {
    position: relative;
    width: 100%;
    p {
      position: absolute;
      top: 20%;
      left: 5%;
      width: 400px;
      @media (max-width: 768px) {
        position: relative;
        width: auto;
        left: 0;
        top: 0;
      }
    }
    p:nth-child(2) {
      position: absolute;
      top: 45%;
      left: 5%;
      width: 400px;

      @media (max-width: 768px) {
        position: relative;
        width: auto;
        left: 0;
        top: 0;
      }
    }

    img {
      width: 100%;
      height: 400px;
      object-position: center;
      object-fit: cover;
      border-radius: 10px;

      @media (max-width: 768px) {
        margin: 2em auto;
      }
    }
  }
  img {
    width: 100%;
    height: 450px;
    object-fit: cover;
    object-position: bottom;
    border-radius: 10px;
    margin: 20px auto;
    @media (min-width: 1500px) {
      width: 50%;
      height: 150px;
    }
  }

  .flex {
    display: flex;
    height: 500px;
    @media (max-width: 768px) {
      display: flex;
      flex-direction: column;
    }
    span {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      padding: 10px;
      height: 100%;
    }
  }
}

.cardImg {
  width: 50%;
  img {
    width: 100%;
    border-top-right-radius: 16px;
    border-bottom-right-radius: 16px;
    height: 100%;
  }
  @media (max-width: 768px) {
    width: 100%;
    border-bottom-left-radius: 16px;
    border-top-right-radius: 0px;
    border-bottom-left-radius: 16px;
  }
}

.firstpaperWrap {
  margin: 0 auto;
  padding: 2em 0;
  width: 80%;
  h3 {
    color: #fff;
    font-size: 20px;
    // margin: 20px 0;
    font-weight: 500;
  }
  div {
    display: flex;
    justify-content: center;
    flex-direction: column;
  }
}

.download {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  @media (max-width: 468px) {
    flex-direction: column;
    align-items: start;
  }
}

.firstpaper {
  position: relative;
  border-radius: 10px;
  height: 330px;
  // width: 530px;
  width: 100%;
  cursor: pointer;
  margin: 3.5em auto;
  h2 {
    color: #fff;
    position: absolute;
    top: 30%;
    left: 10%;
    width: 400px;
    font-size: 48px;
    font-weight: 700;
    @media (max-width: 768px) {
      font-size: 32px;
      left: 7%;
      width: 350px;
      top: 40%;
    }
    @media (max-width: 468px) {
      font-size: 18px;
      left: 5%;
      width: 170px;
      top: 35%;
    }
  }
  img {
    border-radius: 10px;

    @media (max-width: 768px) {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
    @media (max-width: 468px) {
      width: 100%;
      height: 100%;
      object-fit: contain;
      margin: 0;
      padding: 0;
    }
  }

  @media (max-width: 1024px) {
    // height: 250px;
    margin: 0;
  }
  @media (max-width: 768px) {
    height: 250px;
    margin: 0;
  }
  @media (max-width: 468px) {
    height: 120px;
    margin: 0;
  }
}

.thirdpaper {
  position: relative;
  display: inline-flex;
  height: 330px;
  cursor: pointer;
  margin: 20px 10px;
  width: 1010px;
  padding: 112px 522px 112px 51px;
  align-items: center;
  flex-shrink: 0;
  border-radius: 10px;
  background: linear-gradient(
    147deg,
    #b671c9 0%,
    #5960f5 25.92%,
    #41affa 60.38%,
    #585ff4 79.29%
  );

  h2 {
    color: #fff;
    position: absolute;
    top: 30%;
    left: 10%;
    width: 400px;
    font-size: 48px;
    font-weight: 700;
  }
}

.pageTitle {
  width: 100%;
  height: 273px;
  position: relative;
  span {
    background: rgba(14, 1, 33, 0.7);

    width: 100%;
    height: 100%;
    position: absolute;
    z-index: 100;
  }
  h2 {
    color: #fff;
    font-size: 32px;
    font-weight: 700;
    letter-spacing: 0.96px;
    position: absolute;
    top: 30%;
    z-index: 1000;
    left: 10%;

    @media (max-width: 468px) {
      font-size: 22px;
    }
  }
  h1 {
    position: absolute;
    color: #fff;
    font-size: 32px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: 0.96px;
    top: 10%;
    z-index: 1000;
    left: 10%;
  }
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.page {
  width: 80%;
  margin: 30px auto;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  div {
    width: 80%;
    height: 80%;
    display: flex;
    justify-content: center !important;
    align-items: center !important;
    margin: 3em auto;
    img {
      width: 100%;
      @media (min-width: 1500px) {
        width: 100%;
        height: 300px;
        object-fit: contain;
      }
    }
  }
}

.parent {
  justify-content: center;
  align-items: center;
  text-align: center;
  background: #000000;
}
.content {
  background: #000000;
  // height: 600px;
  width: 100vw;
}

.h1Title {
  position: fixed;
  left: -200px;
  font-size: 1px;
}
.titleParent {
  padding: 4em 5em;
}
.title {
  font-family: "kanit";
  font-style: normal;
  font-weight: 700;
  font-size: 4em;
  line-height: 80px;
  color: #fafaff;
  padding: 30px;
}
.titleParent2 {
  padding: 0em 5em;
}
.row {
  display: flex;
  // justify-content: space-between;
  align-items: center;

  @media screen and (max-width: 950px) {
    flex-direction: column;
  }
}
.rowText {
  font-family: "kanit";
  font-style: normal;
  font-weight: 400;
  font-size: 1.15em;
  line-height: 40px;
  color: #fafaff;
  margin-bottom: 8em;
}
.spaceIcon {
  width: 60%;
  position: relative;
  bottom: 120px;
  //   margin-top: 70px;
  @media screen and (max-width: 950px) {
    width: 90%;
  }
}

a {
  text-decoration: none;
}

.textBtnDiv {
  width: 100%;
}
.imgDiv {
  width: 35vw;
}
.btnDiv {
  margin-bottom: 1em;
}
.btnoverview {
  background: linear-gradient(black, black) padding-box,
    linear-gradient(to right, #3300ff, #c9b2f9, #666dff, #ffffff) border-box;
  box-shadow: 0px 2px 20px -2px rgba(251, 221, 101, 0.05);
  border-radius: 50px;
  border: 2px solid transparent;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  display: flex;
  align-items: center;
  text-align: center;
  color: #ffffff;
  width: fit-content;
  padding: 10px 40px;
}

.cominsoon {
  margin-top: 10em;

  h2 {
    font-style: normal;
    font-weight: 700;
    font-size: 36px;
    line-height: 56px;
    background: linear-gradient(
      90deg,
      #b097e8 23.96%,
      rgba(109, 85, 206, 0.99) 104.07%
    );
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    width: 750px;
  }
}

.sideImage {
  float: right;
  position: relative;
  bottom: 18em;
  width: 443px;
}
.topBTn {
  height: 70px;
  width: 70px;
  border-radius: 70px;
  background: linear-gradient(to right, #3300ff, #c9b2f9, #666dff, #ffffff)
    border-box;
  border: none;
  position: fixed;
  bottom: 30px;
  right: 30px;
  z-index: 99;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}
.arrowup {
  color: white;
  font-size: 2em;
}

.quickBuy {
  background: linear-gradient(302.43deg, #9475d5 -2.77%, #3300ff 97.67%);
  min-height: 614px;
  border-radius: 16px;
  padding: 50px 30px;
  display: flex;
  align-items: center;
  gap: 20px;

  @media screen and (max-width: 850px) {
    flex-direction: column;
  }
  & img {
    width: 60%;

    @media screen and (max-width: 850px) {
      width: 100%;
    }
  }

  &__right {
    & p {
      color: #ffffff;
      font-weight: 700;
      font-size: 20px;
    }

    & button {
      border: 2px solid #ffffff;
      border-radius: 12px;
      background-color: transparent;
      width: 160px;
      height: 48px;
      color: #ffffff;
      font-weight: 700;
    }
  }
}

// RESPONSIVENESS CODE

@media screen and (max-width: 1207px) {
  .title {
    font-size: 3.3em;
  }
}
@media screen and (max-width: 1158px) {
  .title {
    font-size: 3.1em;
  }
}
@media screen and (max-width: 1108px) {
  .title {
    font-size: 3em;
  }
}
@media screen and (max-width: 1084px) {
  .title {
    font-size: 2.9em;
    line-height: 67px;
  }
}
@media screen and (max-width: 1048px) {
  .title {
    font-size: 2.7em;
  }
}
@media screen and (max-width: 1007px) {
  .title {
    font-size: 2em;
    line-height: 40px;
  }
}
@media screen and (max-width: 960px) {
  .title {
    // font-size: 1em;
    line-height: 40px;
  }
  //   .spaceIcon {
  //     // display: none;
  //   }
  .rowText {
    line-height: 30px;
    justify-content: center;
    text-align: center;
  }
  .titleParent2 {
    padding: 0em 3em;
    margin-top: 2em;
  }
  .btnDiv {
    justify-content: center;
    text-align: center;
  }
  .rowText {
    margin-top: 2em;
    margin-bottom: 4em;
    text-align: left;
  }
  .cominsoon {
    margin-top: 3em;
    margin-bottom: 4em;
    h2 {
      width: 100%;
      font-size: 26px;
      line-height: 40px;
    }
  }
  .sideImage {
    display: none;
  }
}
@media screen and (max-width: 933px) {
  //   .backgroundImage {
  //     //    width: 40%;
  //     //  display: none;
  //   }
  .content {
    width: 100%;
  }
  .title {
    line-height: 40px;
    width: 100%;
  }
  .titleParent {
    padding: 2em 0em 5em 4em;
  }
}
@media screen and (max-width: 780px) {
  .backgroundImage {
    //    width: 30%;
    display: none;
  }
  .content {
    width: 100%;
  }
  .title {
    font-size: 2.4em;
    line-height: 60px;
  }
  .ultainDescParent {
    width: 80%;
  }
  .whoweareParent {
    padding: 4em 7em 0em 5em;
  }
}

@media screen and (max-width: 658px) {
  .backgroundImage {
    display: none;
  }
  .content {
    width: 100%;
  }
  .title {
    font-size: 2.4em;
    line-height: 60px;
  }
}

@media screen and (max-width: 650px) {
  .title {
    font-size: 1.5em;
  }
  .titleParent {
    padding: 4em 1.5em;
  }
  .ultainParent {
    padding: 4em 2em 1em 3em;
  }
  .ultain {
    width: 100%;
  }
  .whoweareParent {
    padding: 4em 3em 1em 3em;
  }

  // .textBtnDiv{
  //     width: 80%;
  // }
}
@media screen and (max-width: 380px) {
  .content {
    height: 500px;
  }
  .titleParent {
    padding: 4em 1.5em;
  }
  .title {
    font-size: 1.2em;
    line-height: 50px;
  }
}
