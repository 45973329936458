@font-face {
    font-family: Nunito Sans;
    src: url(../../../../shared/fonts/NunitoSans-Regular.ttf);
}
.modalParent{
    max-width: 600px;
    width: 50% !important;
    border-radius: 24px !important;
    padding-bottom: 2em;
}
.modalContent{
    background: #111114;
    padding: 3em !important;
    display: grid;
    justify-content: center;
    align-items: center;
}
.successIconDiv{
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
}
.modalTitle{
    font-family: 'Nunito Sans';
    font-style: normal;
    font-weight: 700;
    font-size: 1.4em;
    line-height: 38px;
    text-align: center;
    color: #FFFFFF; 
    padding-top: 1.5em;  
}
.modalDesc{
    font-family: 'Nunito Sans';
    font-style: normal;
    font-weight: 600;
    font-size: 0.95em;
    line-height: 25px;
    text-align: center;
    color:#777E90; 
    padding-top: 1em;
}
.centerDiv{
    display: flex;
    align-items: center;
    justify-content: center;
}
.modalBtn{
    border-radius: 90px;
    background: linear-gradient(315deg, #9475d4 0%, #30f 100%);
    height: 50px;
    width: fit-content;
    border: none;
    color: #fff;
    cursor: pointer;
    font-family: 'Nunito Sans';
    font-style: normal;
    font-weight: 600;
    font-size: 0.9em;
    padding: 0em 1.5em;
    margin-top: 2em;
}
.backToDashboard{
    margin-top: 2em;
    font-family: 'Nunito Sans';
    font-style: normal;
    font-weight: 800;
    font-size: 1em;
    line-height: 37px;
    background: var(--primary, linear-gradient(90deg, #C9B2F9 0%, rgba(51, 0, 255, 0.99) 100%));
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}
.main{
    background: #111114;
}
.parent{
    border-radius: 12px;
    display: flex;
    justify-content: center;
}
.box{
    background: #000000;
    margin: 3em;
    width: 100%;
    padding: 5em;

}
.row{
    display: flex;
    justify-content: space-between;
}

.title{
    font-family: 'Nunito Sans';
    font-style: normal;
    font-weight: 800;
    font-size: 1.4em;
    line-height: 37px;
    color: #FFFFFF;  
    padding-top: 2em;
}
.sureTitle{
    font-family: 'Nunito Sans';
    font-style: normal;
    font-weight: 800;
    font-size: 1.4em;
    line-height: 37px;
    color: #FFFFFF;  
    padding-top: 2em;
}
.subTitle{
    font-family: 'Nunito Sans';
    font-style: normal;
    font-weight: 500;
    font-size: 1.1em;
    line-height: 30px;
    color: #FFFFFF;   
    padding-top: 1em;
}

.btn{
    border-radius: 90px;
    background: linear-gradient(315deg, #9475d4 0%, #30f 100%);
    height: 50px;
    border: none;
    color: #fff;
    cursor: pointer;
    font-family: 'Nunito Sans';
    font-style: normal;
    font-weight: 600;
    font-size: 0.9em;
    padding: 0em 1.5em;
    width: 100%;
    margin-top: 2em;
    display: flex;
    align-items: center;
    justify-content: center;
}

.dropdownToggle{
    display:flex;
    border:1px solid;
    border-color:#777E90;
    justify-content:space-between;
    border-radius:7px;
    padding-left:10px;
    padding-right:0px;
    height: 50px;
    align-items:center;
    width: 100%;
    margin-top: 1em;
}
.dropBox{
    margin-top:1em;
    color:#FCFCFD;
    height:100px;
    overflow:auto;
    background:#23262F
}
.listDiv{
    padding-top: 2em;
}
.list{
    font-family: 'Nunito Sans';
    font-style: normal;
    font-weight: 500;
    font-size: 0.89em;
    line-height: 20px;
    color: #777E91;
    padding-top: 1em;
}
.Surelist{
    font-family: 'Nunito Sans';
    font-style: normal;
    font-weight: 500;
    font-size: 0.89em;
    line-height: 20px;
    color: #777E91;
    padding-top: 1.5em;
}
.btnRow{
    display: flex;
    justify-content: space-around;
    margin-top: 2em;
}
.dropDownrow{
    display: flex;
    align-items: center;
}
.inputToken{
    font-family: 'Nunito Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 0.9em;
    line-height: 24px;
    color:#666DFF; 
    padding-right: 0.5em;
}
.borderLine{
    border: 1px solid #777E91;
    height: 15px;
    margin-right: 0.5em;
}
.stepIcon{
    width: 100%;
    margin-top: 2em;
}

.cancelDiv{
    background: #181821;  
    padding: 1em; 
}
.modalTitle{
    font-family: 'Nunito Sans';
    font-style: normal;
    font-weight: 700;
    font-size: 1.4em;
    line-height: 38px;
    text-align: center;
    color: #FFFFFF;   
   
}
.modalDesc{
    font-family: 'Nunito Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 25px;
    text-align: center;
    color: #E6E8EC; 
    padding-top: 1em;
}
.modalDescGreen{
    font-family: 'Nunito Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 25px;
    text-align: center;
    color: #58BD7D; 
    padding-top: 1em;
}
.modalRow{
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 2em;
}
.inputDiv{
    margin-top: 2em;
}
.inputTitle{
    font-family: 'Nunito Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 0.8em;
    line-height: 19px;
    color: #B1B5C3;
    padding-top: 1em;
}
.inputTitleTwo{
    font-family: 'Nunito Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 0.8em;
    line-height: 19px;
    color: #B1B5C3;
}
.input{
    background: rgba(44, 45, 58, 0.05);
    border: 1px solid rgba(157, 165, 178, 0.5);
    backdrop-filter: blur(12px);
    border-radius:7px;
    height: 50px;
    width: 100%;
    padding: 0em 1em;
    color: #FFFFFF;
    margin-top: 0.5em;  
}
.inputDate{
    background: rgba(44, 45, 58, 0.05);
    border: 1px solid #B1B5C4;
    backdrop-filter: blur(12px);
    border-radius:7px;
    height: 50px;
    width: 100%;
    padding: 0em 1em;
    color: #B1B5C4 ;
    margin-top: 1em;
    font-family: 'Nunito Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 0.95em;
    color-scheme: dark;
    display: flex;
}
.percentChargeDiv{
    border-width: 1px;
    border-style: solid;
    backdrop-filter: blur(12px);
    border-radius:7px !important;
    height: 50px;
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding: 0em 1em;
    color: #B1B5C4 ;
    margin-top: 1em;
    font-family: 'Nunito Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 0.95em;
    color-scheme: dark;
    display: flex;
    border-image:  linear-gradient(#B671C9, #5960F5 , #41AFFA , #585FF4) 1;
}
.percentChargeDivTwo{
    background: rgba(44, 45, 58, 0.05);
    border: 1px solid #B1B5C4;
    backdrop-filter: blur(12px);
    border-radius:7px;
    height: 50px;
    width: 100%;
    padding: 0em 1em;
    color: #B1B5C4 ;
    margin-top: 1em;
    font-family: 'Nunito Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 0.95em;
    color-scheme: dark;
    display: flex;
    justify-content: space-between;
}
.amount{
    font-family: 'Nunito Sans';
    font-style: normal;
    font-weight: 500;
    font-size: 0.9em;
    line-height: 24px;
    display: flex;
    align-items: center;
    text-align: right;
    color: #FCFCFD;  
    padding-right: 0.5em;
}
.percentCharge{
    font-family: 'Nunito Sans';
    font-style: normal;
    font-weight: 700;
    font-size: 0.9em;
    line-height: 24px;
    display: flex;
    align-items: center;
    background: linear-gradient(90.76deg, #B671C9 -11.96%, #5960F5 22.52%, #41AFFA 68.36%, #585FF4 93.52%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
}
.percentChargeTwo{
    font-family: 'Nunito Sans';
    font-style: normal;
    font-weight: 500;
    font-size: 0.9em;
    line-height: 24px;
    display: flex;
    align-items: center;
    color: #777E91;
}
.btnTop{
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 1em;
}
.inputMain{
    border: none;
    width: 100%;
    height: 50px;
    background: none;
    font-family: 'Nunito Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 1em;
    line-height: 16px; 
    color: #B1B5C4 ;
    color-scheme: dark;
}
.maxDate{
    font-family: 'Nunito Sans';
    font-style: normal;
    font-weight: 700;
    font-size: 12px;
    line-height: 16px; 
    color: #FFFFFF;  
    opacity: 0.5;
    padding-top: 1em;
}
.insufficient{
    font-family: 'Nunito Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 1em;
    line-height: 16px;
    text-align: center; 
    color: #FFFFFF;
    padding-top: 1.7em;
}
.purchase{
   color: #EAA613;
   cursor: pointer;
}
.outlineBtn{
    background: rgba(44, 45, 58, 0.05);
    border: 1px solid #585FF4;
    backdrop-filter: blur(12px);
    border-radius:7px;
    height: 50px;
    width: 48%;
    padding: 0em 1em;
    color: #FFFFFF;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: 'Nunito Sans';
    font-style: normal;
    font-weight: 600;
    font-size: 0.8em;
    line-height: 19px;
    color: #B1B5C3;
    margin-bottom: 1.5em;
}
.basicBtn{
    border-radius: 8px;
    background: linear-gradient(315deg, #9475d4 0%, #30f 100%);
    height: 50px;
    border: none;
    color: #fff;
    cursor: pointer;
    font-family: 'Nunito Sans';
    font-style: normal;
    font-weight: 600;
    font-size: 0.9em;
    padding: 0em 1em;
    text-align: center;
    width: 48%;
    line-height: 19px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 1.5em;
}
.appleIcon{
    width: 45px;
    padding: 0em 1em;
}
.cancelIcon{
    float: right;
}
.cancel{
    background: linear-gradient(180deg, #EAA613 0%, #EFC262 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
    font-size: 0.8em;
    font-family: 'Nunito Sans';
    font-weight: 600;
    cursor: pointer;
}
.boxBorder{
    box-shadow: 0px 10px 20px rgba(52, 72, 115, 0.06);
    border-radius: 16px;
    border: 1px solid rgb(33, 33, 43);
    padding: 1em;
    margin-top: 1em;
}
.invoiceRow{
    display: flex;
    justify-content: space-between;
    margin-top: 1em;
    align-items: center;
}
.invoiceRowBtn{
    display: flex;
    justify-content: space-between;
    margin-top: 1.7em; 
    align-items: center;
}
.payment{
    font-family: 'Nunito Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 0.9em;
    line-height: 24px;
    color: #FFFFFF 
}
.paymentMethod{
    font-family: 'Nunito Sans';
    font-style: normal;
    font-weight: 700;
    font-size: 0.8em;
    line-height: 24px;
    color: #FFFFFF 
}
.paymentMethodTwo{
    font-family: 'Nunito Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 0.8em;
    line-height: 24px;
    color:#E6E8EC  
}
.invoiceborderLine{
    border:  1px solid rgb(33, 33, 43);
    margin-top: 0.5em;
}
.invoiceborderLineTwo{
    border:  1px solid rgb(33, 33, 43);
    margin-top:2em;
}
.darkAmount{
   color: #757A88
}
.invoiceBtn{
    width: 225px;
    height: 48px;
    background: linear-gradient(0deg, rgba(241, 245, 250, 0.5), rgba(241, 245, 250, 0.5)), #F1F5FA;
    background-blend-mode: multiply, normal;
    border-radius: 8px;
    border: none;
    font-weight: 700;
    font-family: 'Nunito Sans';
}
.invoiceoutlineBtn{
    background: rgba(44, 45, 58, 0.05);
    border: 1px solid rgba(157, 165, 178, 0.5);
    backdrop-filter: blur(12px);
    border-radius: 16px;
    height: 45px;
    width: 70%;
    padding: 0em 1em;
    color: #FFFFFF;
    margin-top: 0.5em;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: 'Nunito Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 0.8em;
    line-height: 19px;
    color: #B1B5C3;
    margin-top: 1em;
    padding: 1em;
}
.bb{
    display: flex;
    justify-content: center;
}
.receiptBtnDiv{
    display: flex;
    justify-content: center;
    margin-top: 1.5em;
    align-items: center;
}
.receiptBtn{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 14px 32px;
    gap: 12px;
    width: 279px;
    height: 45px;
    background: #FF6838;
    border-radius: 90px;   
    border: none;    
}
.receiptBtnText{
    font-family: 'Nunito Sans';
    font-style: normal;
    font-weight: 00;
    font-size: 0.8em;
    line-height: 16px;
    color: #FCFCFD;  
}
.convert{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding:0.4em;
    background: #1E1E28;
    border-radius: 8px;
    font-family: 'Nunito Sans';
    font-style: normal;
    font-weight: 600;
    font-size: 2em;
    color: #F0F1FF;
}
.iconsRow{
    display: flex;
}
.convertDropdown{
    display: flex;
    background: #1E1E28;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    margin-top: 1em;
    padding:1em;
    border-radius: 8px;
}
.usd{
    font-family: 'Nunito Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 0.9em;
    line-height: 26px;
    color: #FFFFFF;
    padding: 0em 0.5em;
}
.set{
    font-family: 'Nunito Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 0.9em;
    line-height: 26px;    
    text-align: center;
    color: #AEAEAE;
    padding-left: 0.6em;
}
.switchDiv{
    display: flex;
    margin-top: 1em;
    align-items: center;
}
.convertBtn{
    gap: 10px;
    border: none;
    height: 44px;     
    font-family: 'Nunito Sans';
    font-style: normal;
    font-weight: 600;
    font-size: 1em;
    line-height: 28px;
    text-align: center;
    color: #1E252B;
    cursor: pointer;
    width: 100%;
    background: linear-gradient(180deg, #EAA613 0%, #EFC262 100%);
    border-radius: 14px;
}
.resend{
    font-family: 'Nunito Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 0.9em;
    line-height: 21px;
    text-align: center;
    text-decoration-line: underline;
    color: #B1B5C3;
    padding-top: 2em;
    cursor: pointer;
}
.resend:hover{
    color: #EAA613;
    font-weight: 700;
}
.rowBack{
    display: flex;
    align-items: center;
    cursor: pointer;
    padding: 2em 0em 0em 2em ;
}
.sure{
    font-family: 'Nunito Sans';
    font-style: normal;
    font-weight: 700;
    font-size: 1.2em;
    line-height: 21px;
    color: #ffffff;
    padding: 1em;
}
.convertDiv{
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 1em;
}
@media screen and (max-width: 1000px) {
    .box{
        width: 55%;
    }
}
@media screen and (max-width: 750px) {
    .box{
        width: 65%;
    }
    .modalParent{
        max-width: 600px;
        width: 70% !important;
        border-radius: 24px !important;
        padding-bottom: 2em;
    }
}
@media screen and (max-width: 660px) {
    .box{
        width: 75%;
    }
}




