.quickBuy {
    background-color: #000;
    height: calc(100vh - 90px);
    display: grid;
    grid-template-columns: 2fr 3fr;
    @media screen and (max-width: 850px) {
      grid-template-columns: 1fr;
    }
  
    &__btn {
      height: 56px;
      background: linear-gradient(302.43deg, #9475d5 -2.77%, #3300ff 97.67%);
      border-radius: 4px;
      width: 100%;
      border: none;
      cursor: pointer;
      color: #fff;
      font-weight: 700;
    }
  
    &__left {
      background-image: url(../../assets/quickBuy.png);
      background-size: cover;
      background-position: center;
      background-repeat: no-repeat;
      @media screen and (max-width: 850px) {
        display: none;
      }
    }
  
    &__right {
      padding: 30px;
      display: flex;
      align-items: center;
      // justify-content: center;
      flex-direction: column;
      color: #fff;
      height: inherit;
      // height: calc(100vh - 90px);
      overflow: auto;
  
      &__logoutBtn {
        width: 70%;
        display: flex;
        justify-content: space-between;
        margin-bottom: 20px;
        flex-wrap: wrap;
        gap: 25px;
        align-items: center;
  
        @media screen and (max-width: 600px) {
          width: 100%;
        }
  
        & img {
          cursor: pointer;
        }
  
        &__main {
          display: flex;
          flex-wrap: wrap;
          gap: 25px;
          align-items: center;
  
          & span {
            background-color: #df2040;
            font-weight: 700;
            cursor: pointer;
            color: #fff;
            width: 95px;
            height: 39px;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 13px;
          }
  
          & button {
            cursor: pointer;
            border-radius: 13px;
            background: linear-gradient(315deg, #9475d4 0%, #30f 100%);
            width: 175px;
            height: 39px;
            color: #fff;
            border: none;
            font-weight: 700;
          }
        }
      }
  
      &__authBtn {
        display: flex;
        gap: 40px;
        flex-wrap: wrap;
        margin-bottom: 50px;
  
        & button {
          border-radius: 90px;
          background: var(
            --new-1,
            linear-gradient(132deg, #5158f6 0%, #822df5 52.6%, #f3a761 100%)
          );
          width: 130px;
          height: 39px;
          color: #d9d9d9;
          border: none;
          font-weight: 700;
          cursor: pointer;
        }
      }
  
      &__select {
        & select {
          border: 2px solid #777e91;
          border-radius: 4px;
          width: 100%;
          height: 50px;
          background-color: transparent;
          margin-bottom: 20px;
          color: #ffffff;
          padding: 0 20px;
        }
      }
      &__input {
        & input {
          border: 2px solid #777e91;
          border-radius: 4px;
          width: 100%;
          height: 50px;
          background-color: transparent;
          margin-bottom: 20px;
          color: #ffffff;
          padding: 0 20px;
  
          &::-webkit-outer-spin-button,
          &::-webkit-inner-spin-button {
            -webkit-appearance: none;
            margin: 0;
          }
  
          &::placeholder {
            color: #a8abb3;
          }
        }
      }
  
      &__wallet {
        max-width: 540px;
        & h2 {
          font-weight: 700;
          font-size: 24px;
          margin-bottom: 30px;
          text-align: center;
        }
  
        & h3 {
          font-size: 14px;
          font-weight: 400;
          border-radius: 4px;
          border: 1px solid #f0f1ff;
          display: inline-block;
          padding: 5px;
        }
  
        &__disc {
          margin-bottom: 30px;
          & p {
            color: #fff;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            margin-bottom: 0;
          }
        }
  
        &__instr {
          background: #c9b2f9;
          padding: 20px;
          color: #30f;
          font-size: 15px;
          margin-top: 30px;
        }
  
        &__option {
          background-color: #161322;
          padding: 20px;
          display: flex;
          align-items: center;
          border-radius: 8px;
          margin-bottom: 30px;
          gap: 30px;
          justify-content: space-between;
          flex-wrap: wrap;
  
          & h4 {
            font-size: 20px;
            font-weight: 700;
          }
  
          & p {
            color: #fff;
            font-size: 12px;
            font-style: normal;
            font-weight: 600;
            max-width: 300px;
          }
        }
  
        &__list {
          display: flex;
          justify-content: center;
          flex-wrap: wrap;
          gap: 20px;
          margin-bottom: 20px;
  
          &__item {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            padding: 5px 0;
            width: 120px;
            height: 110px;
            background: #161322;
            border-radius: 8px;
            font-size: 14px;
            text-align: center;
            cursor: pointer;
  
            &:hover {
              background: linear-gradient(#161322, #161322) padding-box,
                linear-gradient(to right, #5158f6, #822df5, #f3a761) border-box;
              box-shadow: 0px 2px 20px -2px rgba(251, 221, 101, 0.05);
              border: 2px solid transparent;
            }
  
            & img {
              width: 45px;
              margin-bottom: 10px;
            }
  
            &.active {
              border-radius: 8px;
              background: linear-gradient(#161322, #161322) padding-box,
                linear-gradient(to right, #5158f6, #822df5, #f3a761) border-box;
              box-shadow: 0px 2px 20px -2px rgba(251, 221, 101, 0.05);
              border: 2px solid transparent;
            }
          }
        }
        &__btn {
          margin-top: 40px;
        }
  
        &__net {
          display: flex;
          flex-direction: column;
  
          & h3 {
            font-weight: 700;
            font-size: 24px;
            margin-bottom: 30px;
            text-align: center;
            margin-top: 80px;
          }
  
          & select {
            border-radius: 13px;
            background: #252527;
            border: none;
            width: 100%;
            height: 53px;
            color: #fff;
            text-align: center;
            font-size: 18px;
            font-weight: 700;
  
            &:focus {
              border: 1px solid #822df5;
              outline: none;
            }
          }
  
          & button {
            margin-top: 30px;
          }
        }
      }
  
      &__promo {
        max-width: 341px;
        text-align: center;
        & h2 {
          font-weight: 700;
          font-size: 36px;
          margin-bottom: 12px;
        }
  
        & input {
          border: 2px solid #777e91;
          border-radius: 4px;
          background-color: transparent;
          color: #fff;
          height: 56px;
          width: 100%;
          margin-top: 20px;
          margin-bottom: 50px;
          padding: 0 20px;
          text-align: center;
          letter-spacing: 3px;
          font-size: 20px;
        }
      }
  
      &__connect {
        max-width: 540px;
        width: 100%;
        display: flex;
        flex-direction: column;
        //   align-items: center;
  
        & h2 {
          font-weight: 700;
          font-size: 24px;
          margin-bottom: 50px;
        }
  
        &__inputCon {
          width: 100%;
          // max-width: 460px;
          display: flex;
          flex-direction: column;
          gap: 10px;
          // align-items: center;
          margin: 0 auto;
  
          & input,
          select {
            width: 100%;
            border: 1px solid #777e91;
            border-radius: 5px;
            background-color: transparent;
            padding: 0 20px;
            height: 56px;
            margin-bottom: 20px;
            // text-align: center;
            // letter-spacing: 3px;
            font-size: 20px;
            color: #fff;
  
            &:disabled {
              color: #777e90;
            }
          }
        }
      }
  
      &__connected {
        max-width: 540px;
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
  
        & h2 {
          font-weight: 700;
          font-size: 24px;
          margin-bottom: 40px;
        }
  
        & p {
          color: #aeaeae;
          margin-bottom: 16px;
        }
  
        &__address {
          width: 278px;
          height: 48px;
          display: flex;
          align-items: center;
          justify-content: center;
          background: #23262f;
          border-radius: 5px;
          font-size: 14px;
          margin-bottom: 50px;
        }
      }
  
      &__purchase {
        max-width: 422px;
        & h2 {
          font-weight: 700;
          font-size: 30px;
          margin-bottom: 40px;
          text-align: center;
        }
  
        &__inputText {
          border: 2px solid #777e91;
          border-radius: 4px;
          width: 100%;
          height: 50px;
          background-color: transparent;
          margin-bottom: 20px;
          color: #ffffff;
          padding: 0 20px;
          display: flex;
          align-items: center;
          color: #777e90;
        }
  
        & h3 {
          font-weight: 700;
          font-size: 20px;
          color: #b1b5c3;
          text-align: center;
          margin-bottom: 25px;
        }
  
        &__method {
          border: 2px solid #777e91;
          border-radius: 4px;
          width: 100%;
          height: 50px;
          background-color: transparent;
          margin-bottom: 20px;
          color: #ffffff;
          padding: 0 20px;
          display: flex;
          align-items: center;
          gap: 20px;
          font-weight: 700;
          cursor: pointer;
        }
      }
  
      &__net {
        display: flex;
        align-items: center;
        gap: 17px;
        flex-wrap: wrap;
  
        & p {
          border-radius: 13px;
          background: #252527;
          height: 39px;
          display: flex;
          align-items: center;
          padding: 0 20px;
          font-size: 16px;
        }
      }
  
      &__comfirm {
        border-radius: 16px;
        width: 100%;
        max-width: 550px;
        display: flex;
        flex-direction: column;
        align-items: center;
  
        & h3 {
          font-weight: 700;
          font-size: 30px;
          margin-bottom: 15px;
        }
  
        & h4 {
          font-weight: 700;
          font-size: 16px;
          color: #777e90;
          margin-bottom: 10px;
          max-width: 450px;
          text-align: center;
        }
  
        &__main {
          border: 1px solid #2a2a39;
          width: 100%;
          border-radius: 12px;
          margin-top: 20px;
          padding: 20px;
          color: #fff;
          margin-bottom: 30px;
          font-size: 15px;
  
          & p {
            display: flex;
            align-items: center;
            margin-bottom: 10px;
            justify-content: space-between;
            margin-top: 20px;
  
            & span {
              display: inline-block;
              border: none;
              width: 200px;
              height: 40px;
              border-radius: 8px;
              background: linear-gradient(
                  0deg,
                  rgba(241, 245, 250, 0.5),
                  rgba(241, 245, 250, 0.5)
                ),
                #f1f5fa;
              color: #23262f;
              display: flex;
              align-items: center;
              justify-content: center;
            }
          }
  
          &__item {
            padding: 10px 0;
            border-bottom: 1px solid #2a2a39;
            display: flex;
            align-items: center;
            justify-content: space-between;
          }
        }
  
        &__btnCon {
          display: flex;
          gap: 40px;
          flex-wrap: wrap;
  
          &__download {
            width: 250px;
            height: 48px;
            border-radius: 12px;
            background: linear-gradient(black, black) padding-box,
              linear-gradient(to right, #9475d4, #3300ff) border-box;
            box-shadow: 0px 2px 20px -2px rgba(251, 221, 101, 0.05);
            border: 2px solid transparent;
            display: flex;
            justify-content: center;
            align-items: center;
            gap: 10px;
          }
  
          & button {
            width: 200px;
            height: 48px;
          }
        }
      }
  
      &__await {
        background: #111114;
        border-radius: 16px;
        padding: 30px;
        max-width: 667px;
        width: 100%;
        text-align: center;
  
        & h2 {
          font-weight: 700;
          font-size: 24px;
          margin-bottom: 16px;
        }
  
        & button {
          margin-top: 30px;
        }
      }
    }
  }
  