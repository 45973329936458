.getLoanMain {
  padding: 20px;
  background-color: #111114;

  &__main {
    display: grid;
    grid-template-columns: 1fr 1fr;

    gap: 40px;

    @media screen and (max-width: 700px) {
      grid-template-columns: 1fr;
    }

    &__item {
      cursor: pointer;
      border-radius: 17px;
      background: #000;
      padding: 30px 20px;
      display: flex;
      flex-direction: column;
      align-items: center;

      border: 1px solid transparent;
      background: linear-gradient(black, black) padding-box,
        linear-gradient(94.96deg, #5158f6 0.8%, #822df5 52.98%, #f3a761 100%)
          border-box;
      background-color: transparent;

      & h4 {
        font-size: 16px;
        font-weight: 600;
      }

      &__period {
        display: flex;
        align-items: center;
        gap: 20px;
        margin: 30px 0;
        flex-wrap: wrap;

        & span {
          border-radius: 9.671px;
          background: #000;
          display: flex;
          justify-content: center;
          align-items: center;
          width: 80px;
          height: 32px;

          &.active {
            background: linear-gradient(315deg, #9475d4 0%, #30f 100%);
          }
        }
      }

      & h5 {
        font-size: 16px;
        font-weight: 600;
        margin-bottom: 38px;
      }

      &__amount {
        margin-bottom: 50px;
        & p {
          margin-bottom: 0;
          font-size: 30px;
          font-style: normal;
          font-weight: 700;
        }

        & span {
          display: flex;
          justify-content: center;
          background: linear-gradient(
            147deg,
            #b671c9 0%,
            #5960f5 25.92%,
            #41affa 60.38%,
            #585ff4 79.29%
          );
          background-clip: text;
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
          font-size: 17px;
          font-weight: 700;
        }
      }

      &__pro {
        &__top {
          display: flex;
          justify-content: space-between;
          align-items: center;
          gap: 20px;
          flex-wrap: wrap;
          font-size: 15px;
          font-weight: 700;

          & span {
            color: #58bd7d;
          }
        }

        & p {
          margin-bottom: 30px;
          font-size: 22px;
          font-style: normal;
          font-weight: 700;
          margin-top: 11px;
        }
      }
    }
  }

  &__loans {
    &__list {
      margin-top: 30px;
      display: grid;
      grid-template-columns: 1fr 1fr;
      @media screen and (max-width: 700px) {
        grid-template-columns: 1fr;
      }

      gap: 40px;

      &__item {
        background-color: #000;
        padding: 20px;
        border-radius: 13px;
        cursor: pointer;
        color: #888889;
        border: 1px solid transparent;
        background: linear-gradient(black, black) padding-box,
          linear-gradient(94.96deg, #5158f6 0.8%, #822df5 52.98%, #f3a761 100%)
            border-box;
        background-color: transparent;

        &.repaid {
          cursor: auto;
          opacity: 0.5;
        }

        &__days {
          width: 170px;
          height: 49px;
          margin: 0 auto;
          border-radius: 12px;
          background: #09090b;
          display: flex;
          align-items: center;
          justify-content: center;
          gap: 10px;
          color: #fff;
          font-size: 21px;
          margin-bottom: 20px;
        }

        &__disc {
          display: flex;
          justify-content: space-between;
          gap: 30px;
          flex-wrap: wrap;
          margin-bottom: 20px;
          align-items: center;

          &__main {
            & h4 {
              font-size: 14px;
              font-weight: 500;
              color: inherit;
            }

            & p {
              font-size: 24px;
              font-style: normal;
              font-weight: 500;
              color: #fff;
            }
          }

          &__amount {
            & p {
              font-size: 24px;
              font-style: normal;
              font-weight: 500;
              color: #fff;
            }

            & span {
              color: #40bf6a;
            }
          }
        }
      }
    }
  }

  &__loan {
    &__main {
      margin-top: 30px;
      display: grid;
      grid-template-columns: 5fr 3fr;
      gap: 20px;
      margin-bottom: 30px;
      @media screen and (max-width: 850px) {
        grid-template-columns: 1fr;
      }

      &__left {
        &__top {
          border-radius: 8px;
          background: #000;
          // display: flex;
          // justify-content: space-between;
          padding: 20px;
          // align-items: center;
          border: 1px solid transparent;
          background: linear-gradient(black, black) padding-box,
            linear-gradient(
                94.96deg,
                #5158f6 0.8%,
                #822df5 52.98%,
                #f3a761 100%
              )
              border-box;
          background-color: transparent;

          &__left {
            position: relative;
            margin-top: 15px;

            &__img {
              position: absolute;
              right: 10px;
              bottom: 10px;
            }
            &__header {
              width: 100%;
              display: flex;
              align-items: center;
              justify-content: space-between;
              gap: 20px;
              flex-wrap: wrap;

              &__right {
                display: flex;
                gap: 10px;
                flex-wrap: wrap;

                & span {
                  padding: 3px 11px;
                  font-size: 12px;
                  font-weight: 700;
                  border-radius: 6px;

                  &.active {
                    background: linear-gradient(315deg, #9475d4 0%, #30f 100%);
                  }
                }
              }
            }
            & h4 {
              margin-top: 42px;
              color: #fff;
              font-size: 14px;
              font-weight: 500;
              margin-bottom: 5px;
            }

            & p {
              color: #fff;
              font-size: 27px;
              font-weight: 700;
              margin-bottom: 0;

              & span {
                color: #b1b5c3;
                font-size: 14px;
              }
            }
          }
          &__right {
            margin-top: 100px;
          }
        }

        &__bottom {
          margin-top: 20px;
          border-radius: 8px;
          background-color: #000;
          padding: 30px 20px;
          margin-bottom: 26px;

          display: flex;
          justify-content: space-between;
          align-items: center;

          flex-wrap: wrap;
          gap: 10px;

          & p {
            font-size: 14px;
            font-weight: 600;
            opacity: 0.5;
            margin-bottom: 10px;
          }

          & span {
            font-size: 24px;
            font-weight: 700;
          }
        }
      }

      &__right {
        &__loan {
          border-radius: 8px;
          background: #000;
          padding: 20px;
          border: 1px solid transparent;
          background: linear-gradient(black, black) padding-box,
            linear-gradient(
                94.96deg,
                #5158f6 0.8%,
                #822df5 52.98%,
                #f3a761 100%
              )
              border-box;
          background-color: transparent;
          margin-bottom: 20px;
          &__top {
            display: flex;
            align-items: flex-start;
            justify-content: space-between;
            gap: 10px;
            flex-wrap: wrap;
            margin-bottom: 5px;

            & h4 {
              font-size: 14px;
              font-weight: 700;
            }

            & p {
              font-size: 27px;
              font-weight: 700;
              margin-bottom: 0;
              & span {
                font-size: 14px;
                font-weight: 700;
              }
            }

            &__per {
              border-radius: 24px;
              background: #58bd7d;
              padding: 2px 8px;
              font-size: 14px;
              font-weight: 700;
            }
          }

          &__bottom {
            display: flex;
            align-items: flex-start;
            justify-content: space-between;
            align-items: center;
            gap: 10px;
            flex-wrap: wrap;

            & h5 {
              font-size: 10px;
              font-weight: 600;
            }

            & p {
              font-size: 16px;
              font-weight: 700;
              margin-bottom: 0;
            }

            & img {
              height: 75px;
            }
          }
        }

        &__collateral {
          border-radius: 8px;
          padding: 20px;
          min-height: 265px;
          // border: 1px solid transparent;
          // background: linear-gradient(black, black) padding-box,
          //   linear-gradient(
          //       94.96deg,
          //       #5158f6 0.8%,
          //       #822df5 52.98%,
          //       #f3a761 100%
          //     )
          //     border-box;
          // background-color: transparent;
          background-color: #000;
          background-image: url(../../assets/collateralBg.png);
          background-position: bottom right;
          background-repeat: no-repeat;

          & h4 {
            font-size: 14px;
            font-weight: 700;
          }

          & p {
            font-size: 25px;
            font-weight: 700;
            margin-bottom: 0;
            & span {
              font-size: 14px;
              font-weight: 700;
            }
          }

          &__per {
            border-radius: 24px;
            background: #58bd7d;
            padding: 2px 8px;
            font-size: 14px;
            font-weight: 700;
            margin-top: 10px;
            display: inline-block;
          }
        }
      }
    }

    &__title {
      font-size: 20px;
      margin: 20px 0;
      font-weight: 500;
    }

    &__list {
      &__item {
        padding: 20px;
        margin-bottom: 20px;
        background-color: #000;
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 20px;
        flex-wrap: wrap;

        &.show {
          border: 1px solid transparent;
          background: linear-gradient(black, black) padding-box,
            linear-gradient(
                94.96deg,
                #5158f6 0.8%,
                #822df5 52.98%,
                #f3a761 100%
              )
              border-box;
          background-color: transparent;
        }
        &__left {
          max-width: 600px;

          & h5 {
            font-size: 20px;
            font-weight: 500;
            margin-bottom: 15px;
          }
        }
      }
    }

    &__warning {
      background: rgba(246, 159, 159, 0.87);
      padding: 20px;
      color: #7b0707;
      font-size: 15px;
      margin-bottom: 20px;
    }

    &__time {
      padding: 20px;
      background: #000;
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-bottom: 20px;
      border-radius: 13px;

      & h3 {
        font-size: 20px;
        font-weight: 500;
        margin-bottom: 32px;
      }

      &__main {
        border-radius: 16px;
        background: #000;
        padding: 30px;
        display: flex;
        gap: 30px;
        margin-bottom: 30px;
        // flex-wrap: wrap;

        &__item {
          display: flex;
          flex-direction: column;
          gap: 15px;
          align-items: center;
          color: #fff;

          &__num {
            border-radius: 11px;
            background: linear-gradient(315deg, #9475d4 0%, #30f 100%);
            width: 100px;
            height: 80px;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 40px;
            font-weight: 900;

            @media screen and (max-width: 550px) {
              width: 70px;
              font-size: 25px;
              height: 50px;
            }
            @media screen and (max-width: 450px) {
              width: 55px;
              font-size: 25px;
              height: 45px;
            }
          }
        }
      }
    }

    &__btn {
      display: flex;
      justify-content: center;
      margin-bottom: 20px;
    }

    &__note {
      padding: 20px;
      background-color: #000;
      margin-bottom: 20px;

      & h3 {
        font-size: 23px;
        font-weight: 500;
      }
      & li {
        margin-bottom: 10px;
      }
    }

    &__get {
      padding: 20px;
      background-color: #000;
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 20px;
      flex-wrap: wrap;

      & h3 {
        font-size: 22px;
        font-weight: 500;
      }
    }
  }

  &__btn {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 30px 0;

    & button {
      border-radius: 4px;
      background: linear-gradient(315deg, #9475d4 0%, #30f 100%);
      width: 100%;
      max-width: 800px;
      height: 56px;
      border: none;
      color: #fff;
      font-weight: 700;
      cursor: pointer;
    }
  }
}
