.career {
  background-color: #000;
  color: #fff;
  min-height: 800px;

  &__modal {
    background-color: #161322;
    position: relative;
    padding-top: 30px;

    &__close {
      position: absolute;
      width: 30px;
      top: 5px;
      right: 0px;
      cursor: pointer;
    }
  }

  &__header {
    background-image: url(../../assets/career.png);
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    padding: 30px;
    min-height: 154px;
    padding-left: 8rem;
    @media screen and (max-width: 950px) {
      padding-left: 6em;
    }
    @media screen and (max-width: 650px) {
      padding-left: 3em;
    }
    @media screen and (max-width: 450px) {
      padding-left: 1.5em;
    }
    margin-bottom: 50px;
    display: flex;
    align-items: center;

    & h2 {
      display: inline-block;
      background: linear-gradient(
        90deg,
        #c9b2f9 23.96%,
        rgba(51, 0, 255, 0.99) 104.07%
      );
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      background-clip: text;
      font-weight: 700;
      font-size: 40px;
    }
  }

  &__mainCon {
    padding: 0 8em;

    @media screen and (max-width: 950px) {
      padding: 0 6em;
    }
    @media screen and (max-width: 650px) {
      padding: 0 3em;
    }
    @media screen and (max-width: 450px) {
      padding: 0 1.5em;
    }
  }

  &__main {
    width: 100%;
    max-width: 1018px;
    margin: 0 auto;

    & h3 {
      font-weight: 600;
      font-size: 30px;
      margin-bottom: 30px;
    }

    &__list {
      &__item {
        background: #161322;
        border-radius: 8px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 30px;
        gap: 30px;
        flex-wrap: wrap;
        margin-bottom: 30px;

        & h4 {
          font-weight: 700;
          font-size: 24px;
        }

        & p {
          font-weight: 400;
          font-size: 20px;

          & span {
            display: inline-block;
            background: linear-gradient(
              94.96deg,
              #5158f6 0.8%,
              #822df5 27.15%,
              #f3a761 97.42%
            );
            cursor: pointer;
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            background-clip: text;
          }
        }
        & button {
          border: none;
          width: 121px;
          height: 38px;
          background: linear-gradient(
            302.43deg,
            #9475d5 -2.77%,
            #3300ff 97.67%
          );
          border-radius: 4px;
          display: flex;
          align-items: center;
          justify-content: center;
          gap: 10px;
          color: #fff;
        }

        &__left__btn {
          display: flex;
          align-items: center;
          gap: 20px;
          flex-wrap: wrap;

          & span {
            cursor: pointer;
            background: linear-gradient(315deg, #9475d4 0%, #30f 100%);
            background-clip: text;
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
          }
        }
      }
    }
  }

  &__apply {
    padding-bottom: 300px;
    & h3 {
      font-weight: 500;
      font-size: 36px;
      border-bottom: 1px solid #4a455b;
      padding-bottom: 24px;
      margin-bottom: 50px;
    }

    &__form {
      &__inputCon {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        margin-bottom: 30px;

        & label {
          width: 170px;
        }

        & input {
          width: 650px;
          height: 48px;
          border-radius: 4px;
          border: 1px solid transparent;
          background: linear-gradient(black, black) padding-box,
            linear-gradient(to right, #9475d4, #3300ff) border-box;
          transition: 0.3s;
          background-color: transparent;
          padding: 0 20px;
          color: #fff;

          &:focus {
            outline: none;
            background: linear-gradient(black, black) padding-box,
              linear-gradient(to right, #5158f6, #822df5, #f3a761) border-box;
          }
        }
      }

      &__upload {
        display: flex;
        align-items: center;
        flex-wrap: wrap;

        & p {
          width: 170px;
          margin-bottom: 0;
        }

        &__main {
          cursor: pointer;
          width: 180px;
          height: 32px;
          background-color: #232324;
          border-radius: 2px;
          display: flex;
          align-items: center;
          padding: 10px 18px;
          gap: 10px;
          font-weight: 400;
          font-size: 14px;
        }

        &__name {
          color: #1890ff;
          font-size: 14px;
          height: 32px;
          display: flex;
          align-items: center;
          border-radius: 2px;
        }
      }

      & hr {
        border-top: #4a455b solid;
        margin: 40px 0;
        opacity: 1;
      }

      & h4 {
        font-weight: 600;
        font-size: 24px;
        margin-bottom: 40px;
      }

      &__btn {
        display: flex;
        justify-content: center;

        & button {
          color: #fff;
          margin-top: 50px;
          background: linear-gradient(
            302.43deg,
            #9475d5 -2.77%,
            #3300ff 97.67%
          );
          border: navajowhite;
          border-radius: 51.7241px;
          width: 280px;
          height: 48px;
        }
      }
    }
  }

  &__modal__disc {
    color: #ffffff;

    & h3 {
      font-size: 20px;
      font-weight: 700;
      margin-bottom: 30px;
    }
    & h4 {
      font-size: 19px;
      font-weight: 700;
      margin-bottom: 20px;
    }
  }
}
