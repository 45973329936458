.getLoan {
  background-color: #111114;
  padding: 20px;

  &__select {
    & select {
      border: 2px solid #777e91;
      border-radius: 4px;
      width: 100%;
      height: 50px;
      background-color: transparent;
      margin-bottom: 20px;
      color: #ffffff;
      padding: 0 20px;
    }

    & label {
      color: #777e91;
      margin-bottom: 10px;
    }
  }
  &__input {
    & label {
      color: #777e91;
      margin-bottom: 10px;
    }
    & input {
      border: 2px solid #777e91;
      border-radius: 4px;
      width: 100%;
      height: 50px;
      background-color: transparent;
      margin-bottom: 20px;
      color: #ffffff;
      padding: 0 20px;

      &::-webkit-outer-spin-button,
      &::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }

      &::placeholder {
        color: #a8abb3;
      }
    }
  }

  &__inputText {
    border: 2px solid #777e91;
    border-radius: 4px;
    width: 100%;
    height: 50px;
    background-color: transparent;
    margin-bottom: 20px;
    color: #ffffff;
    padding: 0 20px;
    display: flex;
    align-items: center;
    color: #777e90;
    justify-content: space-between;
  }

  &__header {
    display: flex;
    justify-content: space-between;
    gap: 30px;
    flex-wrap: wrap;
    align-items: center;
    margin-bottom: 35px;

    & h2 {
      font-size: 24px;
      font-style: normal;
      font-weight: 800;
      max-width: 507px;
    }

    &__btn {
      display: flex;
      align-items: center;
      gap: 20px;
      flex-wrap: wrap;

      & button {
        border-radius: 13px;
        background: linear-gradient(315deg, #9475d4 0%, #30f 100%);
        width: 166px;
        height: 39px;
        border: none;
        color: #fff;
        font-weight: 700;
        cursor: pointer;

        &.alt {
          background: transparent;
          border: 1px solid #9475d4;
        }
      }
    }
  }

  &__disc {
    background-image: url(../../assets/loanBg.png);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    min-height: 156px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 30px;
    border-radius: 16px;
    margin-bottom: 30px;

    & h3 {
      font-size: 26px;
      font-style: normal;
      font-weight: 600;
    }
    & p {
      margin: 0;
    }
  }

  &__title {
    font-size: 24px;
    font-style: normal;
    font-weight: 800;
    margin-bottom: 30px;
  }

  &__list {
    &__item {
      padding: 30px;
      margin-bottom: 30px;
      background-color: #000;

      &.show {
        border: 1px solid transparent;
        background: linear-gradient(black, black) padding-box,
          linear-gradient(94.96deg, #5158f6 0.8%, #822df5 52.98%, #f3a761 100%)
            border-box;
        background-color: transparent;
      }

      & h4 {
        font-size: 25px;
        font-style: normal;
        font-weight: 400;
      }

      & p {
        color: rgba(255, 255, 255, 0.7);
        font-size: 19px;
        font-style: normal;
        font-weight: 400;
      }
    }
  }

  &__btn {
    display: flex;
    justify-content: center;
    margin-bottom: 50px;

    & button {
      border-radius: 4px;
      background: linear-gradient(315deg, #4404c9 0%, #30f 100%);
      width: 80%;
      height: 56px;
      border: none;
      color: #fff;
      font-weight: 700;
      cursor: pointer;

      &:disabled {
        background: linear-gradient(
          315deg,
          #4204c975 0%,
          rgba(51, 0, 255, 0.431) 100%
        );
        color: #ffffff75;
      }
    }
  }

  &__form {
    margin: 50px 30px;
    background: #000;
    padding: 30px;

    @media screen and (max-width: 600px) {
      margin: 50px 0px;
      padding: 20px;
    }

    &__header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 20px;
      flex-wrap: wrap;
      margin-bottom: 30px;

      & button {
        border-radius: 13px;
        background: linear-gradient(315deg, #9475d4 0%, #30f 100%);
        width: 256px;
        height: 39px;
        border: none;
        color: #fff;
        font-weight: 700;
        cursor: pointer;
      }
    }

    &__label {
      color: #777e91;
      margin-bottom: 10px;
    }

    &__avail {
      color: #777e90;
      font-size: 16px;
      font-weight: 700;
      margin-bottom: 30px;

      & span {
        color: #ee0e0e;

        &.suc {
          color: #008f7e;
        }
      }
    }

    &__pur {
      color: #fdb7b7;
      text-align: center;
      margin-bottom: 20px;

      & a {
        color: #c9b2f9;
        text-decoration: underline !important;
        cursor: pointer;
      }
    }

    &__terms {
      display: flex;
      gap: 20px;

      & p {
        margin-bottom: 0;

        & span {
          color: #c9b2f9;
          text-decoration: underline !important;
          cursor: pointer;
        }
      }
    }

    &__disc {
      color: #c9b2f9;
      font-size: 16px;
    }
  }

  &__modal {
    max-width: 650px;
    background-color: #111114;
    padding: 50px 30px;
    display: flex;
    flex-direction: column;
    align-items: center;
    color: #fff;
    position: relative;

    & h3 {
      color: #fff;
      font-size: 22px;
      margin-top: 30px;
      margin-bottom: 20px;
    }

    & button {
      border-radius: 13px;
      background: linear-gradient(315deg, #9475d4 0%, #30f 100%);
      width: 186px;
      height: 39px;
      border: none;
      color: #fff;
      font-weight: 700;
      cursor: pointer;
      margin-top: 30px;
    }

    &__close {
      position: absolute;
      top: 10px;
      right: 10px;
      cursor: pointer;
    }
  }

  &__modalTerms {
    max-width: 950px;
    background-color: #111114;
    padding: 50px 30px;
    color: #fff;
    position: relative;

    &__close {
      position: absolute;
      top: 10px;
      right: 10px;
      cursor: pointer;
    }

    & h3 {
      font-size: 24px;
      margin-bottom: 30px;
    }

    &__main {
      padding: 20px;
      background-color: #000;
    }
  }

  &__calc {
    &__header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      flex-wrap: wrap;
      margin-bottom: 40px;

      & h3 {
        font-size: 24px;
        font-style: normal;
        font-weight: 800;
      }

      & img {
        cursor: pointer;
      }
    }

    &__main {
      margin: 0 30px;
      padding: 30px 7%;
      background: linear-gradient(
        132deg,
        #5158f6 0%,
        #822df5 52.6%,
        #f3a761 100%
      );
      margin-bottom: 50px;

      @media screen and (max-width: 600px) {
        margin: 0px;
        padding: 30px 20px;
        margin-bottom: 50px;
      }

      & label {
        color: #fff;
      }

      & input,
      select {
        background: #000;
        border: none;
      }

      &__inputText {
        border-radius: 4px;
        width: 100%;
        height: 50px;
        background-color: #000;
        margin-bottom: 20px;
        color: #ffffff;
        padding: 0 20px;
        display: flex;
        align-items: center;
        color: #777e90;
        justify-content: space-between;
      }

      &__inputCon {
        gap: 40px;
        display: grid;
        grid-template-columns: 1fr 1fr;
        @media screen and (max-width: 800px) {
          grid-template-columns: 1fr;
          gap: 0px;
        }
      }

      &__vs {
        border: 3px solid #c9b2f9;
        padding: 20px;
        margin-bottom: 70px;
        margin-top: 30px;

        &__inputCon {
          display: grid;
          grid-template-columns: 3fr 1fr 3fr;
          @media screen and (max-width: 800px) {
            grid-template-columns: 1fr;
            // gap: 0px;
          }

          & span {
            display: flex;
            height: 100%;
            justify-content: center;
            align-items: center;
            font-size: 30px;
            font-weight: 800;
          }
        }
      }
    }

    &__next {
      margin: 0 30px;

      & h4 {
        font-size: 22px;
        font-weight: 700;
        margin-bottom: 31px;
      }

      &__main {
        display: grid;
        grid-template-columns: 1fr 1fr;
        @media screen and (max-width: 800px) {
          grid-template-columns: 1fr;
        }

        gap: 40px;

        &__item {
          display: flex;
          align-items: center;
          justify-content: space-between;
          padding: 30px;
          background-color: #000;
          gap: 10px;
          flex-wrap: wrap;

          & button {
            border-radius: 4px;
            background: linear-gradient(315deg, #9475d4 0%, #30f 100%);
            width: 156px;
            height: 40px;
            border: none;
            color: #fff;
            font-weight: 700;
            cursor: pointer;
          }
        }
      }
    }
  }
}
