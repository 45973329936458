.buyNow {
  padding: 20px;
  background-color: #111114;

  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 20px;
    flex-wrap: wrap;
    margin-bottom: 20px;

    &__right {
      display: flex;
      gap: 20px;
      flex-wrap: wrap;
    }
  }

  &__balance {
    background: #000;
    border-radius: 10px;
    padding: 30px;
    display: flex;
    justify-content: space-between;
    gap: 30px;
    flex-wrap: wrap;
    align-items: center;
    margin-bottom: 30px;

    &__left {
      & h3 {
        font-weight: 400;
        font-size: 18px;
        color: #aeaeae;
        margin-bottom: 8px;
      }

      & b {
        font-weight: 800;
        font-size: 60px;
        color: #fff;
        margin-bottom: 8px;

        & span {
          font-weight: 400;
          font-size: 14px;
          color: #aeaeae;
        }
      }

      & p {
        color: #aeaeae;
      }
    }

    & img {
      height: 200px;
    }
  }

  &__tokens {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 20px;
    margin-bottom: 30px;

    @media screen and (max-width: 800px) {
      grid-template-columns: repeat(2, 1fr);
    }
    @media screen and (max-width: 550px) {
      grid-template-columns: 1fr;
    }

    &__item {
      padding: 20px;
      background-color: #000;
      border-radius: 16px;

      &__token {
        display: flex;
        gap: 20px;
        justify-content: space-between;
        margin-bottom: 10px;
        flex-wrap: wrap;
        & h4 {
          font-size: 18px;
          font-style: normal;
          font-weight: 400;
        }

        &__left {
          display: flex;
          gap: 10px;

          & img {
            width: 50px;
            height: 50px;
          }

          & p {
            font-size: 14px;
            color: #b1b5c3;
          }
        }

        &__right {
          & p {
            color: #40bf6a;
          }
        }
      }

      &__amo {
        font-size: 14px;
        margin-top: 10px;
        & span {
          color: #fff;
        }

        & p {
          margin: 0;
        }
      }
    }
  }

  &__table {
    overflow: auto;
    padding-bottom: 40px;
    background-color: #000;
    padding: 30px 1px;
    border-radius: 12px;

    &__header {
      margin-bottom: 20px;
      padding: 0 20px;

      &__title {
        font-size: 24px;
        font-style: normal;
        font-weight: 700;
      }
    }

    &__body {
      display: flex;
      flex-direction: column;
      //   gap: 8px;
      min-width: 900px;
    }

    &__row {
      gap: 10px;
      padding: 20px;
      display: flex;
      align-items: center;
      height: 60px;
      color: #fff;
      font-size: 14px;
      font-weight: 500;

      &:nth-child(even) {
        background-color: #111114;
      }

      //   &.head {
      //     // background: linear-gradient(180deg, #666dff 0%, #5057e4 100%);
      //     // font-weight: 700;
      //     // font-size: 14px;
      //     // height: 36px;
      //   }

      &__data {
        &__link {
          color: #d8b566;
          text-decoration: underline;
        }
      }
    }
  }

  &__purchase {
    &__main {
      padding: 50px 30px;
      background-color: #000;
      margin-top: 30px;

      & h3 {
        font-size: 23px;
        font-weight: 600;
        margin-bottom: 30px;
      }

      &__avail {
        color: #777e90;
        font-size: 16px;
        font-weight: 700;
        margin-bottom: 30px;

        & span {
          color: #008f7e;
        }
      }
    }
  }

  &__modal {
    background-color: #111114;
    padding: 50px 30px;
    color: #fff;
    position: relative;
    border-radius: 16px;

    &__close {
      position: absolute;
      top: 10px;
      right: 10px;
      cursor: pointer;
    }

    &__congrats {
      border-radius: 16px;
      width: 100%;
      max-width: 550px;
      display: flex;
      flex-direction: column;
      align-items: center;

      & h3 {
        font-weight: 700;
        font-size: 30px;
        margin-bottom: 15px;
      }

      & h4 {
        font-weight: 700;
        font-size: 16px;
        color: #777e90;
        margin-bottom: 10px;
        max-width: 450px;
        text-align: center;
      }

      &__main {
        border: 1px solid #2a2a39;
        width: 100%;
        border-radius: 12px;
        margin-top: 20px;
        padding: 20px;
        color: #fff;
        margin-bottom: 30px;
        font-size: 15px;

        & p {
          display: flex;
          align-items: center;
          margin-bottom: 10px;
          justify-content: space-between;
          margin-top: 20px;

          & span {
            display: inline-block;
            border: none;
            width: 200px;
            height: 40px;
            border-radius: 8px;
            background: linear-gradient(
                0deg,
                rgba(241, 245, 250, 0.5),
                rgba(241, 245, 250, 0.5)
              ),
              #f1f5fa;
            color: #23262f;
            display: flex;
            align-items: center;
            justify-content: center;
          }
        }

        &__item {
          padding: 10px 0;
          border-bottom: 1px solid #2a2a39;
          display: flex;
          align-items: center;
          justify-content: space-between;
        }
      }

      &__btnCon {
        display: flex;
        gap: 40px;
        flex-wrap: wrap;

        &__download {
          width: 250px;
          height: 48px;
          border-radius: 12px;
          background: linear-gradient(black, black) padding-box,
            linear-gradient(to right, #9475d4, #3300ff) border-box;
          box-shadow: 0px 2px 20px -2px rgba(251, 221, 101, 0.05);
          border: 2px solid transparent;
          display: flex;
          justify-content: center;
          align-items: center;
          gap: 10px;
        }

        & button {
          width: 200px;
          height: 48px;
        }
      }
    }
  }
}
