.loan__invoice {
  padding: 2em;
  h2 {
    color: #fff;
    font-size: 24px;
    font-style: normal;
    font-weight: 800;
    line-height: normal;
  }

  &__wrap {
    width: 844px;
    flex-shrink: 0;
    border-radius: 12px;
    background: #000;
    padding: 2em;
    margin: 2em;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    &__link {
      color: #c9b2f9;
      font-size: 20px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      text-align: center;
      margin: 2em auto;
      cursor: pointer;
      text-decoration-line: underline;
    }
    h3 {
      color: #fff;
      font-size: 32px;
      font-style: normal;
      font-weight: 700;
      text-align: center;
      line-height: 40px; /* 125% */
    }
    &__content {
      border-radius: 12px;
      border: 1px solid #2a2a39;
      width: 70%;
      //   display: flex;
      padding: 32px;
      margin: 1em auto;
      //   flex-direction: column;
      //   align-items: center;
      h3 {
        color: #fff;
        font-size: 16px;
        margin-bottom: 2em;
        font-style: normal;
        text-align: left;
        font-weight: 700;
        line-height: 24px; /* 150% */
      }
      span {
        display: flex;
        align-items: center;
        justify-content: space-between;
        // padding: 2em;
        p {
          color: #e6e8ec;
          font-size: 15px;
          font-style: normal;
          font-weight: 400;
          line-height: 24px; /* 160% */
        }
        h3 {
          color: #fcfcfd;
          text-align: right;

          /* Caption (Bold) */
          font-size: 15px;
          font-style: normal;
          font-weight: 700;
          line-height: 24px; /* 160% */
        }
      }
    }
  }
  &__id {
    width: 70%;
    //   display: flex;
    padding: 32px;
    margin: 1em auto;
    span {
      display: flex;
      align-items: center;
      justify-content: space-between;
      p {
        color: #e6e8ec;
        font-size: 15px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px; /* 160% */
      }
      h3 {
        color: #fcfcfd;
        text-align: right;

        /* Caption (Bold) */
        font-size: 15px;
        font-style: normal;
        font-weight: 700;
        line-height: 24px; /* 160% */
      }
      .id {
        display: flex;
        width: 235px;
        padding: 12px;
        justify-content: center;
        align-items: center;
        gap: 10px;
        border-radius: 8px;
        background: linear-gradient(
            0deg,
            rgba(241, 245, 250, 0.5) 0%,
            rgba(241, 245, 250, 0.5) 100%
          ),
          #f1f5fa;
        background-blend-mode: multiply, normal;
        color: var(--neutrals-2, #23262f);
        font-size: 15px;
        font-style: normal;
        font-weight: 700;
        line-height: 24px; /* 160% */
      }
    }
  }
  &__note {
    width: 666px;
    height: 71px;
    flex-shrink: 0;
    background: #c9b2f9;
    color: #30f;
    font-size: 15px;
    font-style: normal;
    border-radius: 6px;
    font-weight: 600;
    padding: 1em;
    line-height: 24px; /* 160% */
  }
}

.success-msg {
  color: #58bd7d;
  text-align: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px; /* 150% */
}

.congrats {
  color: #fff;
  text-align: center;
  font-size: 32px;
  font-style: normal;
  font-weight: 800;
  line-height: 100%; /* 32px */
  letter-spacing: -0.64px;
}
