.chart {
  background: #000;
  padding: 20px;
  border-radius: 13px;
  margin-top: 25px;
  margin-bottom: 25px;

  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 20px;
    margin-bottom: 30px;
    flex-wrap: wrap;

    &__amount {
      color: #fff;
      font-size: 20px;
      display: flex;
      align-items: center;
      gap: 20px;
      flex-wrap: wrap;

      & span {
        font-size: 14px;
        color: #58bd7d;
        margin-left: 5px;
      }
    }
    &__disc {
      display: flex;
      align-items: center;
      flex-wrap: wrap;

      & h3 {
        font-size: 21px;
        font-weight: 400;
        margin-right: 20px;
      }
    }

    &__action {
      &__btn {
        border-radius: 9.67091px;
        width: 74.65px;
        height: 32.58px;
        border: none;
        color: #fff;
        background: #2d7645;
      }
    }
  }
}
