@font-face {
  font-family: kanit;
  src: url(../../shared/utils/fonts/Kanit-Regular.ttf);
}
.header {
  height: 90px;
  background: #000000;
  box-shadow: 0px 4px 25px rgba(0, 0, 0, 0.15);
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding: 0em 5em;
}
.logoParent {
  display: flex;
  align-items: center;
  cursor: pointer;
}
.buyModalCon {
  background-color: #161322;
  color: #ffffff;

  & h3 {
    font-weight: 700;
    font-size: 36px;
  }
}

.title {
  font-family: "Kanit";
  font-style: normal;
  font-weight: 500;
  font-size: 0.9em;
  line-height: 28px;
  text-align: center;
  color: #ffffff;
  cursor: pointer;
  text-decoration: none;
}

.titleAlt {
  color: #9475d4;
}

.title:hover {
  color: #9475d5;
}
.btnDiv {
  padding-left: 1em;
  display: flex;
  align-items: center;
  gap: 10px;

  &__btn {
    border-radius: 12px;
    background: linear-gradient(black, black) padding-box,
      linear-gradient(to right, #9475d4, #3300ff) border-box;
    box-shadow: 0px 2px 20px -2px rgba(251, 221, 101, 0.05);
    border: 2px solid transparent;
    color: #fff;
    min-width: 100px;
    min-height: 40px;
    cursor: pointer;
    font-size: 14px;
  }

  &__btnAlt {
    color: #fff;
    min-width: 100px;
    min-height: 40px;
    background: linear-gradient(302.43deg, #c9b2f9 -2.77%, #3300ff 97.67%);
    border-radius: 12px;
    cursor: pointer;
    border: none;
    font-size: 14px;
  }
}
.menuDiv {
  cursor: pointer;
}
.menu {
  color: white;
  font-size: 2em;
  display: none;
}
.modalParent {
  background-color: #161616;
}
.modalMenu {
  display: grid;
  gap: 20px;
}
.modalClose {
  display: flex;
  justify-content: flex-end;
}
.modalMenufirst {
  display: grid;
  gap: 20px;
}

.dropdown {
  position: relative;
  display: inline-block;
}

.dropdownContent {
  display: none;
  position: absolute;
  background-color: #ffffff;
  min-width: 400px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1000;

  @media screen and (max-width: 600px) {
    min-width: 200px;
    width: 300px;
  }
  @media screen and (max-width: 350px) {
    min-width: 200px;
    width: 270px;
  }
}
.dropDownRow {
  display: flex;
  align-items: center;
  margin: 0.5em 1em;
}
.dropdownContent a {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: flex;
  align-items: center;
}
.dropIcon {
  width: 50px;
}
.dropIconTwo {
  width: 30px;
}
.logoDrodownDiv {
  background: #161616;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  height: 60px;
  width: 70px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 60px;
}
.logoTitleDiv {
  background: #fafaff;
  border-radius: 0px 16px 16px 0px;
  margin-left: 0.2em;
  height: 60px;
  width: 90%;
  display: flex;
  align-items: center;
  padding: 0em 1em;
  cursor: pointer;
}
.dropDowntitle {
  font-family: "Kanit";
  font-style: normal;
  font-weight: 400;
  font-size: 0.9em;
  // @media screen and (max-width: 350px) {
  //   font-size: 0.6em;
  // }
  line-height: 32px;
  text-transform: uppercase;
  color: #1e252b;
}
.dropDowntitle:hover {
  color: #9475d5;
}

.dropdown:hover .dropdownContent {
  display: block;
}

.dropdown:hover .dropbtn {
  background-color: #3e8e41;
}
@media screen and (max-width: 1230px) {
  .ultainfinity {
    display: none;
  }
  .logoText {
    display: none;
  }
  .btnDiv {
    padding-left: 0em;
  }
}
@media screen and (max-width: 990px) {
  .title {
    display: none;
  }
  .titleModal {
    font-family: "Kanit";
    font-style: normal;
    font-weight: 500;
    font-size: 1.7em;
    line-height: 48px;
    text-align: center;
    color: #ffffff;
    cursor: pointer;
    text-decoration: none;
  }

  .titleModalbutton {
    // font-size: 15px;
    // line-height: 48px;
    // text-align: center;
    display: flex;
    justify-content: center;
  }

  .titleModal:hover {
    color: #3300ff;
  }
  .menu {
    display: inline;
    cursor: pointer;
  }
  .header {
    justify-content: space-between;
  }
  .btnDiv {
    display: none;
  }
  .dropdown {
    text-align: center;
  }
}
@media screen and (max-width: 650px) {
  .header {
    padding: 0em 3em;
  }
  .btnDiv {
    display: none;
  }
}
