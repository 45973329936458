.completeOrder {
  background-color: #111114;
  padding: 20px;

  &__table {
    overflow: auto;
    padding-bottom: 40px;
    padding-top: 40px;

    &__body {
      display: flex;
      flex-direction: column;
      gap: 8px;
      min-width: 800px;
    }

    &__row {
      gap: 10px;
      padding: 20px;
      display: flex;
      align-items: center;
      height: 55px;
      //   @include themed() {
      //     background-color: t("bgAlt");
      //   }

      &:nth-child(even) {
        background-color: #000;
      }

      &__head {
        font-size: 14px;
      }

      &__data {
        font-weight: 400;
        font-size: 15px;
        line-height: 20px;
        // @include themed() {
        //   color: t("textAlt");
        // }

        & span {
          text-decoration-line: underline;
          color: #eee3af;
          cursor: pointer;
        }
      }
    }
  }

  &__back {
    display: flex;
    justify-content: flex-end;

    & button {
      border-radius: 4px;
      background: var(--main, linear-gradient(315deg, #9475d4 0%, #30f 100%));
      height: 40px;
      width: 170px;
      color: #fff;
      font-weight: 600;
      border: none;
      margin-bottom: 100px;
    }
  }

  &__main {
    max-width: 550px;
    width: 100%;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    align-items: center;

    & h2 {
      font-size: 32px;
      font-style: normal;
      font-weight: 700;
    }

    &__disc {
      text-align: center;
      margin-bottom: 20px;
    }

    &__comfirm {
      border-radius: 16px;
      width: 100%;
      max-width: 550px;
      display: flex;
      flex-direction: column;
      align-items: center;

      & h3 {
        font-weight: 700;
        font-size: 30px;
        margin-bottom: 15px;
      }

      & h4 {
        font-weight: 700;
        font-size: 16px;
        color: #777e90;
        margin-bottom: 10px;
        max-width: 450px;
        text-align: center;
      }

      &__main {
        border: 1px solid #2a2a39;
        width: 100%;
        border-radius: 12px;
        margin-top: 20px;
        padding: 20px;
        color: #fff;
        margin-bottom: 30px;
        font-size: 15px;

        & p {
          display: flex;
          align-items: center;
          margin-bottom: 10px;
          justify-content: space-between;
          margin-top: 20px;

          & span {
            display: inline-block;
            border: none;
            width: 200px;
            height: 40px;
            border-radius: 8px;
            background: linear-gradient(
                0deg,
                rgba(241, 245, 250, 0.5),
                rgba(241, 245, 250, 0.5)
              ),
              #f1f5fa;
            color: #23262f;
            display: flex;
            align-items: center;
            justify-content: center;
          }
        }

        &__item {
          padding: 10px 0;
          border-bottom: 1px solid #2a2a39;
          display: flex;
          align-items: center;
          justify-content: space-between;
        }
      }

      &__btnCon {
        display: flex;
        gap: 40px;
        flex-wrap: wrap;
        margin-top: 30px;
        justify-content: space-between;

        &__download {
          width: 250px;
          height: 48px;
          border-radius: 12px;
          background: linear-gradient(black, black) padding-box,
            linear-gradient(to right, #9475d4, #3300ff) border-box;
          box-shadow: 0px 2px 20px -2px rgba(251, 221, 101, 0.05);
          border: 2px solid transparent;
          display: flex;
          justify-content: center;
          align-items: center;
          gap: 10px;
        }

        & button {
          width: 200px;
          height: 48px;
          border-radius: 4px;
          background: linear-gradient(315deg, #4404c9 0%, #30f 100%);
          border: none;
          color: #fff;
          font-weight: 700;
        }
      }
    }
  }
}
