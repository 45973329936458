.pendingOrder {
  background-color: #111114;
  padding: 20px;

  &__table {
    overflow: auto;
    padding-bottom: 40px;
    padding-top: 40px;

    &__body {
      display: flex;
      flex-direction: column;
      gap: 8px;
      min-width: 800px;
    }

    &__row {
      gap: 10px;
      padding: 20px;
      display: flex;
      align-items: center;
      height: 55px;
      //   @include themed() {
      //     background-color: t("bgAlt");
      //   }

      &:nth-child(even) {
        background-color: #000;
      }

      &__head {
        font-size: 14px;
      }

      &__data {
        font-weight: 400;
        font-size: 15px;
        line-height: 20px;
        // @include themed() {
        //   color: t("textAlt");
        // }

        & span {
          text-decoration-line: underline;
          color: #eee3af;
          cursor: pointer;
        }
      }
    }
  }

  &__back {
    display: flex;
    justify-content: flex-end;

    & button {
      border-radius: 4px;
      background: var(--main, linear-gradient(315deg, #9475d4 0%, #30f 100%));
      height: 40px;
      width: 170px;
      color: #fff;
      font-weight: 600;
      border: none;
      margin-bottom: 100px;
    }
  }

  &__main {
    max-width: 550px;
    width: 100%;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 50px;

    text-align: center;
  }
}
