.verify {
  &__logo {
    display: flex;
    justify-content: center;
    margin-bottom: 50px;
  }
  &__main {
    max-width: 500px;
    display: flex;
    flex-direction: column;
    align-items: center;
    color: #fff;
    position: relative;

    & h3 {
      color: #fff;
      font-size: 22px;
      margin-top: 30px;
      margin-bottom: 20px;
    }

    & p {
      text-align: center;
    }

    &__bio {
      border-radius: 8px;
      background: linear-gradient(141deg, #eaa613 0%, #efc262 100%);
      cursor: pointer;
      margin-top: 30px;
      width: 331px;
      height: 40px;
      font-weight: 700;
      color: #171b23;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    &__btns {
      display: flex;
      align-items: center;
      gap: 50px;
      flex-wrap: wrap;

      & span {
        color: #b6cbff;
        cursor: pointer;
        margin-top: 30px;
      }
    }

    & button {
      border-radius: 13px;
      background: linear-gradient(315deg, #9475d4 0%, #30f 100%);
      width: 186px;
      height: 39px;
      border: none;
      color: #fff;
      font-weight: 700;
      cursor: pointer;
      margin-top: 30px;
    }

    &__close {
      position: absolute;
      top: 10px;
      right: 10px;
      cursor: pointer;
    }
  }
}
