.row {
  display: flex;
  justify-content: space-around;
  margin-top: 30px;
  gap: 30px;
}
.leftText {
  border-radius: 16px;
  background: linear-gradient(black, black) padding-box,
    linear-gradient(to right, #3300ff, #c9b2f9, #666dff, #ffffff) border-box;
  box-shadow: 0px 2px 20px -2px rgba(251, 221, 101, 0.05);
  border: 2px solid transparent;
  width: fit-content;
  padding: 45px 40px;
  width: 700px;
  @media screen and (max-width: 800px) {
    width: 100%;
  }
  justify-content: space-around;

  h1 {
    font-style: normal;
    font-weight: 700;
    font-size: 32px;
    line-height: 130%;
    color: #f7f7f7;
  }
  p {
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 30px;
    font-feature-settings: "salt" on, "liga" off;
    color: #ffffff;
  }
}

.rightText {
  border-radius: 16px;
  background: linear-gradient(black, black) padding-box,
    linear-gradient(to right, #3300ff, #c9b2f9, #666dff, #ffffff) border-box;
  box-shadow: 0px 2px 20px -2px rgba(251, 221, 101, 0.05);
  border: 2px solid transparent;
  width: fit-content;
  padding: 45px 40px;
  width: 700px;
  @media screen and (max-width: 800px) {
    width: 100% !important;
  }
  justify-content: space-around;

  h1 {
    font-style: normal;
    font-weight: 700;
    font-size: 32px;
    line-height: 130%;
    color: #f7f7f7;
  }
  p {
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 30px;
    font-feature-settings: "salt" on, "liga" off;
    color: #ffffff;
  }
}

@media screen and (max-width: 960px) {
  .row {
    display: grid;
  }
}
