.dashboard {
  background-color: #111114;
  padding: 20px;

  & button {
    border-radius: 4px;
    background: linear-gradient(315deg, #9475d4 0%, #30f 100%);
    height: 40px;
    width: 170px;
    border: none;
    color: #fff;
    font-weight: 700;
  }

  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 30px;
    margin-bottom: 24px;

    & h2 {
      font-size: 24px;
      font-style: normal;
      font-weight: 800;
    }

    &__main {
      display: flex;
      gap: 30px;
      align-items: center;
      flex-wrap: wrap;

      & p {
        border-radius: 13px;
        background: #252527;
        height: 39px;
        padding: 5px 15px;
        display: flex;
        align-items: center;
        margin-bottom: 0;
        font-size: 14px;
      }
    }
  }

  &__bal {
    display: grid;
    grid-template-columns: 3fr 2fr;
    gap: 25px;
    margin-bottom: 25px;

    @media screen and (max-width: 700px) {
      grid-template-columns: 1fr;
    }

    &__left {
      padding: 20px;
      border-radius: 13px;
      background: #000;

      &__header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 40px;
        gap: 20px;
        flex-wrap: wrap;

        &__right {
          display: flex;
          gap: 20px;
          flex-wrap: wrap;

          & span {
            border-radius: 6px;
            background: #111114;
            padding: 3px 12px;
            font-size: 12px;
            font-style: normal;
            font-weight: 700;

            &.active {
              background: linear-gradient(
                147deg,
                #b671c9 0%,
                #5960f5 25.92%,
                #41affa 60.38%,
                #585ff4 79.29%
              );
            }
          }
        }
      }

      &__bottom {
        border-radius: 11px;
        background: linear-gradient(
          138deg,
          #5158f6 0%,
          #822df5 52.6%,
          #f3a761 100%
        );
        padding: 10px 20px;

        & p {
          margin-bottom: 0;
        }
      }
    }

    &__right {
      padding: 20px;
      border-radius: 13px;
      background: #000;

      & button {
        border-radius: 13px;
        height: 30px;
      }

      &__item {
        font-size: 14px;
        font-style: normal;
        font-weight: 700;
        display: flex;
        align-items: center;
        margin-bottom: 20px;

        & img {
          width: 30px;
          margin-left: 20px;
        }
      }
    }
  }

  &__title {
    font-size: 22px;
    font-style: normal;
    font-weight: 700;
    margin-bottom: 20px;
  }

  &__details {
    display: grid;
    grid-template-columns: 2fr 1fr;
    gap: 25px;
    color: rgba(255, 255, 255, 0.77);
    margin-bottom: 25px;
    @media screen and (max-width: 940px) {
      grid-template-columns: 1fr;
    }

    &__main {
      display: grid;
      grid-template-columns: 2fr 3fr;
      gap: 25px;
      @media screen and (max-width: 640px) {
        grid-template-columns: 1fr;
      }

      &__item {
        border-radius: 13px;
        background: #000;
        min-height: 55px;
        display: flex;
        align-items: center;
        padding: 10px 20px;
        font-weight: 700;
        color: rgba(255, 255, 255, 0.77);

        & span {
          margin-left: 24px;
        }
      }
    }

    &__message {
      border-radius: 13px;
      background: #000;
      padding: 20px;
      width: 100%;

      & h3 {
        font-size: 16px;
        font-style: normal;
        font-weight: 700;
      }

      & textarea {
        background-color: transparent;
        width: 100%;
        padding: 0 20px;
        border: 1px solid #9475d4;
      }
    }
  }

  &__table {
    overflow: auto;
    // padding-bottom: 40px;

    &__body {
      display: flex;
      flex-direction: column;
      gap: 8px;
      min-width: 800px;
    }

    &__row {
      gap: 10px;
      padding: 20px;
      display: flex;
      align-items: center;
      height: 55px;
      //   @include themed() {
      //     background-color: t("bgAlt");
      //   }

      &:nth-child(even) {
        background-color: #000;
      }

      &__head {
        font-size: 14px;
      }

      &__data {
        font-weight: 400;
        font-size: 15px;
        line-height: 20px;
        // @include themed() {
        //   color: t("textAlt");
        // }
      }
    }
  }

  &__ulit {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 25px;
    margin: 30px 0;
    @media screen and (max-width: 870px) {
      grid-template-columns: 1fr;
    }

    &__item {
      width: 100%;
      border-radius: 13px;
      background: radial-gradient(
          346.47% 124.88% at 100% 77.58%,
          #000 6.7%,
          rgba(17, 1, 30, 0) 36.53%
        ),
        radial-gradient(
          346.47% 124.88% at 100% 77.58%,
          rgba(17, 1, 30, 0) 40.88%,
          #000 95.67%
        );
      display: flex;
      justify-content: space-between;

      @media screen and (max-width: 500px) {
        flex-direction: column;
      }

      & img {
        width: 40%;
        max-height: 200px;

        @media screen and (max-width: 500px) {
          width: 100%;
        }
      }

      &__main {
        margin: 20px 30px;

        & h4 {
          font-size: 22px;
          font-style: normal;
          font-weight: 700;
          margin-bottom: 20px;
        }
      }
    }
  }
}
