body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #000 !important;
}

a {
  text-decoration: none !important;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.modal-overlay-alt {
  background: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(7px);
  z-index: 3;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  height: 100%;
  align-items: center;
}

.videoModalCon {
  position: relative;
}
.videoModalCon__close {
  z-index: 3;
  position: absolute;
  right: 10px;
  top: 10px;
  cursor: pointer;
}
.videoModalCon__close img {
  width: 25px;
}

/* ///////////// react-phone-input-2 ///////////// */
.form-control {
  padding-left: 80px !important;
  /* @include themed() {
    color: t("textAlt");
    background-color: t("bg") !important;
  } */
  background-color: #161322 !important;
  color: #fff !important;
  height: 55px !important;
  width: 100% !important;
  border: 1px solid rgba(157, 165, 178, 0.5) !important;
}
.react-tel-input {
  margin-top: 10px !important;
  margin-bottom: 10px !important;
}

.flag-dropdown {
  display: flex;
  justify-content: center;
  width: 60px !important;
  /* @include themed() {
    background-color: t("bgAlt") !important;
  } */
  background-color: #1b1f28 !important;
  border: 1px solid rgba(157, 165, 178, 0.5) !important;
  border-right: none !important;
}

.flag-dropdown.open {
  background-color: transparent !important;
}
.selected-flag:hover,
.react-tel-input,
.selected-flag:focus {
  background-color: transparent !important;
}

.react-select__control {
  background-color: #202531;
  border: none !important;
}

.react-select__single-value {
  color: #fff !important;
}

/* /////////// PAGINATE //////////// */

.paginationBttns {
  /* height: 20px; */
  list-style: none;
  display: flex;
  justify-content: center;
}

.paginationBttns a {
  height: 33px;
  width: 35px;
  margin: 0 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 700;
  font-size: 14px;
  background-color: #000;
  border-radius: 6px;
  cursor: pointer;
  transition: 0.3s;
}

.paginationBttns a:hover {
  color: white !important;
}

.paginationActive a {
  color: white !important;
  background: linear-gradient(315deg, #9475d4 0%, #30f 100%) !important;
}

.paginationDisabled a {
  color: #868686;
  background-color: transparent;
}
.pignateCon {
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
}

.noData {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 400px;
  font-size: 50px;
  font-weight: 800;
  opacity: 0.2;
}

.loadingCon {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 30px;
}
