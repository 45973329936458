.login {
    background-color: #161322;
    width: 100%;
  
    &__otp {
      display: flex;
      flex-direction: column;
      align-items: center;
  
      &__title {
        font-weight: 800;
        font-size: 32px;
        //   line-height: 20px;
        text-align: center;
        margin: 0;
        margin-bottom: 20px;
      }
  
      &__disc {
        font-weight: 400;
        font-size: 14px;
        line-height: 28px;
        margin: 0;
        margin-bottom: 30px;
        text-align: center;
      }
  
      &__choice {
        display: block;
        text-align: center;
        margin-bottom: 30px;
      }
  
      &__messanger {
        box-shadow: 6px 6px 16px rgba(0, 0, 0, 0.35),
          -6px -6px 16px -7px rgba(52, 47, 47, 0.55);
        border-radius: 12px;
        padding: 30px 15px;
        width: 100%;
        max-width: 500px;
        display: flex;
        align-items: center;
        flex-direction: column;
  
        & p {
          margin-bottom: 20px;
        }
      }
  
      &__socialLinksCon {
        display: flex;
        flex-direction: column;
  
        &__recovery {
          display: flex;
          justify-content: flex-end;
          & span {
            display: inline-block;
            // align-self: flex-end;
            background: linear-gradient(180deg, #666dff 0%, #5057e4 100%);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            background-clip: text;
            font-weight: 400;
            font-size: 14px;
            line-height: 22px;
            mix-blend-mode: normal;
            margin-top: 10px;
          }
        }
      }
  
      &__easy {
        text-align: center;
        font-size: 14px;
        margin-bottom: 20px;
        max-width: 600px;
  
        & b {
          font-size: 16px;
          font-weight: 700;
          margin: 10px;
          display: inline-block;
          // margin-top: 20px;
  
          & span {
            margin-top: 10px;
            display: inline-block;
          }
        }
      }
  
      &__or {
        font-weight: 900;
        font-size: 20px;
        margin: 24px;
      }
  
      &__biometric {
        box-shadow: 6px 6px 16px rgba(0, 0, 0, 0.35),
          -6px -6px 16px -7px rgba(52, 47, 47, 0.55);
        border-radius: 12px;
        padding: 30px 15px;
        width: 100%;
        max-width: 500px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 20px;
  
        &.quick {
          box-shadow: 6px 6px 16px rgba(90, 90, 90, 0.55),
            -6px -6px 16px -7px rgba(101, 101, 101, 0.75);
          min-width: 300px;
        }
  
        &__main {
          display: flex;
          align-items: center;
          justify-content: center;
          gap: 50px;
          cursor: pointer;
        }
  
        &__item {
          display: flex;
          flex-direction: column;
          gap: 10px;
          align-items: center;
        }
      }
    }
  
    &__btnCon {
      display: flex;
      justify-content: center;
      margin-top: 25px;
    }
  
    &__terms {
      font-weight: 400;
      font-size: 14px;
      line-height: 22px;
      text-align: center;
      margin-top: 25px;
  
      & span {
        color: #575eec;
        text-decoration: none;
        cursor: pointer;
      }
    }
  
    &__verify {
      display: flex;
      flex-direction: column;
      align-items: center;
  
      &__title {
        font-weight: 800;
        font-size: 32px;
        //   line-height: 20px;
        text-align: center;
        // @include themed() {
        //   color: t("textAlt");
        // }
        margin: 0;
        margin-bottom: 20px;
      }
  
      &__subTitle {
        font-weight: 400;
        font-size: 14px;
        line-height: 28px;
        margin: 0;
        text-align: center;
        margin-bottom: 25px;
      }
      &__disc {
        color: #777e91;
        font-weight: 400;
        font-size: 14px;
        line-height: 28px;
        margin: 0;
        margin-bottom: 30px;
        text-align: center;
      }
    }
  
    &__user {
      display: flex;
      flex-direction: column;
      align-items: center;
  
      &__title {
        font-weight: 800;
        font-size: 32px;
        //   line-height: 20px;
        text-align: center;
        // @include themed() {
        //   color: t("textAlt");
        // }
        margin: 0;
        margin-bottom: 20px;
      }
  
      &__disc {
        font-weight: 400;
        font-size: 14px;
        line-height: 28px;
        margin: 0;
        margin-bottom: 30px;
        text-align: center;
      }
  
      &__biometric {
        cursor: pointer;
        box-shadow: 6px 6px 16px rgba(0, 0, 0, 0.35),
          -6px -6px 16px -7px rgba(52, 47, 47, 0.55);
        border-radius: 12px;
        padding: 30px 15px;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
      }
  
      &__input {
        position: relative;
        display: flex;
        flex-direction: column;
        // width: 100%;
  
        &__icon {
          position: absolute;
          left: 0;
          top: 0;
          width: 66px;
          height: 60px;
          display: flex;
          align-items: center;
          justify-content: center;
          background: rgba(91, 100, 116, 0.06);
          border-radius: 8px 0px 0px 8px;
        }
  
        & a {
          text-decoration: none;
          & span {
            display: block;
            text-align: right;
            color: #575eec;
          }
        }
      }
    }
  
    &__biom {
      &__title {
        font-weight: 800;
        font-size: 32px;
        //   line-height: 20px;
        text-align: center;
        // @include themed() {
        //   color: t("textAlt");
        // }
        margin: 0;
      }
  
      &__skip {
        text-align: center;
        text-decoration: underline;
        margin-top: 25px;
        // @include themed() {
        //   color: t("text");
        // }
      }
  
      &__add {
        background: linear-gradient(180deg, #666dff 0%, #5057e4 100%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        background-clip: text;
        font-size: 14px;
        margin: 30px 0;
        text-align: center;
      }
  
      &__disc {
        font-weight: 400;
        font-size: 14px;
        line-height: 28px;
        margin: 0;
        margin-bottom: 30px;
        text-align: center;
      }
  
      &__biometric {
        // cursor: pointer;
        box-shadow: 6px 6px 16px rgba(0, 0, 0, 0.35),
          -6px -6px 16px -7px rgba(52, 47, 47, 0.55);
        border-radius: 12px;
        padding: 30px 15px;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
  
        &__suc {
          color: #58bd7d;
          font-weight: 400;
        }
        &__err {
          color: #bd5858;
          font-weight: 400;
        }
  
        &__illus {
          display: flex;
          align-items: center;
          justify-content: center;
          gap: 50px;
          margin-bottom: 30px;
  
          &__item {
            display: flex;
            flex-direction: column;
            gap: 10px;
            align-items: center;
          }
        }
      }
    }
  
    &__skip {
      text-align: center;
      background: var(
        --Gold-Gradient,
        linear-gradient(
          100deg,
          #b88717 1.28%,
          #ffd166 29.81%,
          #f5d281 73.35%,
          #c48e0e 115.29%
        )
      );
      background-clip: text;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      text-decoration: underline;
      cursor: pointer;
    }
  }
  
  .getHelp {
    &__disc {
      font-weight: 400;
      font-size: 14px;
      margin-top: 30px;
      margin-bottom: 10px !important;
    }
  
    &__btn {
      display: flex;
      align-items: center;
      justify-content: center;
      font-weight: 700;
      font-size: 14px;
      color: #000;
      cursor: pointer;
      width: 269px;
      height: 33px;
      background: linear-gradient(180deg, #eaa613 0%, #efc262 100%);
      border-radius: 8px;
    }
  }
  