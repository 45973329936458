.repay__convert {
  position: relative;
  &__back {
    cursor: pointer;
    margin-left: auto;
  }
  &__wrap {
    width: 80%;
    margin: 0 auto;
    border-radius: 14px;
    background: rgba(232, 232, 232, 0.08);
    padding: 2em;

    background-blend-mode: color-dodge;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;

    &__content {
      padding: 1em;
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 80%;
      border-radius: 14px;
      background: #111114;
      height: 158px;
      flex-shrink: 0;

      span {
        display: flex;
        align-items: center;
        h2 {
          color: #f0f1ff;
          font-size: 36px;
          font-style: normal;
          font-weight: 600;
          line-height: normal;
        }
      }
      h1 {
        color: rgba(255, 255, 255, 0.6);
        text-align: right;
        font-size: 47px;
        font-style: normal;
        font-weight: 600;
        line-height: 22px; /* 46.809% */
      }
    }
    div {
      margin: 2em auto;
    }
    &__rate {
      width: 80%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      p {
        color: #fff;
        font-size: 25px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
      }
    }
  }
}
