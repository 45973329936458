@font-face {
  font-family: kanit;
  src: url(../../shared/utils/fonts/Kanit-Regular.ttf);
}
// .parent {
// }
.upperfooterParent {
  background-color: #000000;
  padding: 4em 7em;
  padding-top: 0;

  @media screen and (max-width: 960px) {
    padding-top: 8rem;
  }
}
.communityDiv {
  display: grid;
  justify-content: center;
  align-items: center;
}

.footer__quickBuy {
  display: flex;
  padding: 30px;
  background: linear-gradient(302.43deg, #9475d5 -2.77%, #3300ff 97.67%);
  border-radius: 12px;
  align-items: center;
  gap: 40px;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-top: 80px;

  & p {
    max-width: 696px;
    font-weight: 700;
    font-size: 16px;
    color: #fff;
    margin-bottom: 0;
  }

  & button {
    border: 2px solid #ffffff;
    border-radius: 12px;
    background-color: transparent;
    width: 160px;
    height: 48px;
    color: #ffffff;
    font-weight: 700;
  }
}

.subscribe {
  font-family: "Kanit";
  font-style: normal;
  font-weight: 700;
  font-size: 2.1em;
  line-height: 40px;
  text-align: center;
  background: linear-gradient(315.1deg, #9475d5 7.68%, #3300ff 159.74%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-shadow: 0px 10px 40px rgba(143, 67, 242, 0.2);
}
.desc {
  font-family: "Kanit";
  font-style: normal;
  font-weight: 400;
  font-size: 0.95em;
  line-height: 30px;
  text-align: center;
  color: #f0f0f0;
  // padding: 0em 10em;
}
.socialParent {
  display: grid;
  grid-template-columns: repeat(10, 1fr);
  @media screen and (max-width: 960px) {
    grid-template-columns: repeat(5, 1fr);
  }
  gap: 20px;
  margin: 0 auto;
  margin-top: 30px;
  color: #fff;
  justify-content: center;
  align-items: center;
  max-width: 1000px;
}

.iconsDiv {
  cursor: pointer;
  display: inline-block;
  text-align: center;
  font-size: 14px;
  @media screen and (max-width: 500px) {
    font-size: 12px;
  }
  @media screen and (max-width: 380px) {
    font-size: 9px;
  }

  & img {
    width: 60px;
    margin-bottom: 7px;

    @media screen and (max-width: 650px) {
      width: 40px;
    }
    @media screen and (max-width: 500px) {
      width: 30px;
    }
    @media screen and (max-width: 380px) {
      font-size: 25px;
    }
  }

  & a {
    text-decoration: none;
    color: #fff;
  }
}

.LowerfooterParent {
  background: #161322;
  padding: 2em 5em;
}
.parentRow {
  display: flex;
  // grid-template-columns: repeat(4, 1fr);
  justify-content: space-evenly;
  padding: 10px;
  flex-wrap: wrap;
  gap: 40px;
}
// .rowOne {
//   // width: 10%;
// }
.rowTwo {
  display: flex;
  gap: 40px;
  justify-content: space-between;
  flex-wrap: wrap;
}
.logoParent {
  display: flex;
  align-items: center;
  cursor: pointer;
}
.logoText {
  padding: 0em 2em 0em 1em;
}
.ultainfinity {
  font-family: "Kanit";
  font-style: normal;
  font-weight: 500;
  font-size: 1.25em;
  line-height: 28px;
  color: #ffffff;
}
.global {
  font-family: "Kanit";
  font-style: normal;
  font-weight: 500;
  font-size: 0.8em;
  line-height: 28px;
  color: #4d476b;
}
.footerdesc {
  font-family: "kanit";
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 28px;
  color: #ffffff;
  padding-top: 1em;
}

.top {
  display: flex;
  flex-direction: column;
  gap: 15px;
}
.contentTitle {
  font-family: "kanit";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  // line-height: 38px;
  color: #ffffff !important;
  padding-top: 0em;
  text-decoration: none;
  cursor: pointer;

  & button {
    border-radius: 8px;
    background: linear-gradient(315deg, #9475d4 0%, #30f 100%);
    height: 35px;
    min-width: 150px;
    border: none;
    color: #fff;
    font-size: 14px;
    font-weight: 600;
  }
}
a {
  text-decoration: none;
  color: #fff;
}
// .section {
//   // width: 35%;
// }
// .sectionTwo {
//   // width: 36%;
// }
// .sectionThree {
//   // width: 32%;
// }
.top {
  padding-top: 1.5em;
}
.borderLine {
  border: 0.86px solid white;
  width: 100%;
  margin-top: 2em;
}
// .iconsDiv {
//   height: 37px;
//   width: 37px;
//   border: 2px solid white;
//   border-radius: 7px;
//   display: flex;
//   align-items: center;
//   justify-content: center;
//   margin: 0em 0.5em;
// }
@media screen and (max-width: 870px) {
  .rowOne {
    width: 100%;
  }
  .parentRow {
    display: grid;
    justify-content: inherit;
  }
  .rowTwo {
    width: 100%;
    padding-left: 0em;
  }
  .desc {
    padding: 0em 5em;
  }
}
@media screen and (max-width: 650px) {
  .upperfooterParent {
    padding: 2em 3em;
  }
  .LowerfooterParent {
    padding: 2em 3em;
  }
  .desc {
    padding: 0em 3em;
  }

  .icons {
    font-size: 1em;
  }
}
