.buyModalImg {
  display: flex;
  justify-content: center;
  & img {
    max-width: 822px;
    width: 100%;
    margin: 50px 0;
  }
}

.buyModalCon {
  background-color: #000;
  padding: 5rem 8rem;

  @media screen and (max-width: 950px) {
    padding: 3rem 6em;
  }
  @media screen and (max-width: 650px) {
    padding: 3rem 3em;
  }
  @media screen and (max-width: 450px) {
    padding: 3rem 1.5em;
  }
}

.buyModal {
  //   padding: 30px;
  color: #fff;

  //   @media screen and (max-width: 950px) {
  //     padding: 3rem 6em;
  //   }
  //   @media screen and (max-width: 650px) {
  //     padding: 3rem 3em;
  //   }
  //   @media screen and (max-width: 450px) {
  //     padding: 3rem 1.5em;
  //   }
  max-width: 840px;
  margin: 0 auto;
}
.footer__quickBuy {
  display: flex;
  padding: 30px;
  background: linear-gradient(302.43deg, #9475d5 -2.77%, #3300ff 97.67%);
  border-radius: 12px;
  align-items: center;
  gap: 40px;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-bottom: 50px;

  & p {
    max-width: 696px;
    font-weight: 700;
    font-size: 16px;
    color: #fff;
    margin-bottom: 0;
  }

  & button {
    border: 2px solid #ffffff;
    border-radius: 12px;
    background-color: transparent;
    width: 160px;
    height: 48px;
    color: #ffffff;
    font-weight: 700;
  }
}

.buyModalLink {
  color: #b097e8;
  text-decoration: underline;
  font-size: 20px;
}
.buyModalClose {
  position: absolute;
  width: 30px;
  top: -10px;
  right: 0px;
  cursor: pointer;
}

.quickBuy {
  background: linear-gradient(302.43deg, #9475d5 -2.77%, #3300ff 97.67%);
  min-height: 500px;
  padding: 50px 30px;
  display: flex;
  align-items: center;
  gap: 20px;
  margin-bottom: 30px;
  margin-top: 30px;

  @media screen and (max-width: 850px) {
    flex-direction: column;
  }
  & img {
    width: 60%;

    @media screen and (max-width: 850px) {
      width: 100%;
    }
  }

  &__right {
    & p {
      color: #ffffff;
      font-weight: 700;
      font-size: 20px;
    }

    & button {
      border: 2px solid #ffffff;
      border-radius: 12px;
      background-color: transparent;
      width: 160px;
      height: 48px;
      color: #ffffff;
      font-weight: 700;
    }
  }
}
