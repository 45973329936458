@font-face {
    font-family: Nunito Sans;
    src: url(../../../../shared/fonts/NunitoSans-Regular.ttf);
}
.modalParent{
    max-width: 600px;
    width: 50% !important;
    border-radius: 24px !important;
    padding-bottom: 2em;
}
.sure{
    font-family: 'Nunito Sans';
    font-style: normal;
    font-weight: 700;
    font-size: 1.2em;
    line-height: 21px;
    color: #ffffff;
    padding: 1em;
}
.successIconDiv{
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
}
.modalTitle{
    font-family: 'Nunito Sans';
    font-style: normal;
    font-weight: 700;
    font-size: 1.4em;
    line-height: 38px;
    text-align: center;
    color: #FFFFFF; 
}
.modalDesc{
    font-family: 'Nunito Sans';
    font-style: normal;
    font-weight: 600;
    font-size: 0.95em;
    line-height: 25px;
    text-align: center;
    color:#777E90; 
    padding-top: 1em;
}
.centerDiv{
    display: flex;
    align-items: center;
    justify-content: center;
}
.modalBtn{
    border-radius: 90px;
    background: linear-gradient(315deg, #9475d4 0%, #30f 100%);
    height: 50px;
    width: fit-content;
    border: none;
    color: #fff;
    cursor: pointer;
    font-family: 'Nunito Sans';
    font-style: normal;
    font-weight: 600;
    font-size: 0.9em;
    padding: 0em 1.5em;
    margin-top: 2em;
}
.backToDashboard{
    margin-top: 2em;
    font-family: 'Nunito Sans';
    font-style: normal;
    font-weight: 800;
    font-size: 1em;
    line-height: 37px;
    background: var(--primary, linear-gradient(90deg, #C9B2F9 0%, rgba(51, 0, 255, 0.99) 100%));
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}
.basicBtn{
    border-radius: 8px;
    background: linear-gradient(315deg, #9475d4 0%, #30f 100%);
    height: 50px;
    border: none;
    color: #fff;
    cursor: pointer;
    font-family: 'Nunito Sans';
    font-style: normal;
    font-weight: 600;
    font-size: 0.9em;
    padding: 0em 1em;
    text-align: center;
    width: 70%;
    line-height: 19px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 1.5em;
}
.btn{
    border-radius: 8px;
    background: linear-gradient(315deg, #9475d4 0%, #30f 100%);
    height: 50px;
    border: none;
    color: #fff;
    cursor: pointer;
    font-family: 'Nunito Sans';
    font-style: normal;
    font-weight: 600;
    font-size: 0.9em;
    padding: 0em 1em;
    text-align: center;
    width: 100%;
    line-height: 19px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 2em;
}
.proceedBtn{
    border-radius: 90px;
    background: linear-gradient(315deg, #9475d4 0%, #30f 100%);
    height: 50px;
    border: none;
    color: #fff;
    cursor: pointer;
    font-family: 'Nunito Sans';
    font-style: normal;
    font-weight: 600;
    font-size: 0.9em;
    padding: 0em 1em;
    text-align: center;
    width: 60%;
    line-height: 19px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 2em;
}
.main{
    background: #111114;
}
.parent{
    border-radius: 12px;
    display: flex;
    justify-content: center;
}
.box{
    background: #000000;
    margin: 3em;
    width: 100%;
    padding: 5em;

}
.row{
    display: flex;
    justify-content: space-between;
}

.title{
    font-family: 'Nunito Sans';
    font-style: normal;
    font-weight: 800;
    font-size: 1.4em;
    line-height: 37px;
    color: #FFFFFF;  
    text-align: center;
}
.link{
    color: #C9B2F9;
    font-family: Nunito Sans;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    text-decoration-line: underline;
    text-align: center;
    padding-top: 2em;
    cursor: pointer;
}

.modalDesc{
    font-family: 'Nunito Sans';
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 25px;
    text-align: center;
    color: #B1B5C3; 
    padding-top: 1em;
}
.boxBorder{
    border-radius: 12px;
    border: 1px solid #2A2A39;
    padding: 1em;
    margin-top: 1em;
}
.invoiceRow{
    display: flex;
    justify-content: space-between;
    margin-top: 1em;
    align-items: center;
}
.invoiceRowBtn{
    display: flex;
    justify-content: space-between;
    margin-top: 1.7em; 
    align-items: center;
}
.payment{
    font-family: 'Nunito Sans';
    font-style: normal;
    font-weight: 700;
    font-size: 0.9em;
    line-height: 24px;
    color:#FFF;
}
.paymentMethod{
    font-family: 'Nunito Sans';
    font-style: normal;
    font-weight: 700;
    font-size: 0.8em;
    line-height: 24px;
    color: #FCFCFD;
}
.paymentMethodTwo{
    font-family: 'Nunito Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 0.8em;
    line-height: 24px;
    color:#E6E8EC  
}
.invoiceborderLine{
    border: 0.1px solid #2A2A39;
    margin-top: 0.5em;
}
.invoiceBtn{
    width: 225px;
    height: 48px;
    background: linear-gradient(0deg, rgba(241, 245, 250, 0.5), rgba(241, 245, 250, 0.5)), #F1F5FA;
    background-blend-mode: multiply, normal;
    border-radius: 8px;
    border: none;
    font-weight: 700;
    font-family: 'Nunito Sans';
}
.bb{
    display: flex;
    justify-content: center;
    margin-top: 2em;
}
.invoiceoutlineBtn{
    background:none;
    border: 1px solid #666DFF;
    backdrop-filter: blur(12px);
    border-radius: 16px;
    height: 45px;
    width: 60%;
    padding: 0em 1em;
    color: #ffffff;
    margin-top: 0.5em;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: 'Nunito Sans';
    font-style: normal;
    font-weight: 700;
    font-size: 0.8em;
    line-height: 19px;
    color: #000000;
    margin-top: 1em;
    padding: 1em;
}
.downloadBtn{
    background:#FF6838;
    border: none;
    backdrop-filter: blur(12px);
    border-radius: 16px;
    height: 45px;
    width: 60%;
    padding: 0em 1em;
    color: #000000;
    margin-top: 0.5em;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: 'Nunito Sans';
    font-style: normal;
    font-weight: 700;
    font-size: 0.8em;
    line-height: 19px;
    color: #ffffff;
    margin-top: 1em;
    padding: 1em; 
}
.appleIcon{
    padding-left: 0.5em;
    font-size: 10px;
}
.btc{
    font-family: 'Nunito Sans';
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 25px;
    text-align: center;
    color:#ffffff; 
    text-align: center;
}
.inputTitle{
    font-family: 'Nunito Sans';
    font-style: normal;
    font-weight: 900;
    font-size: 0.75em;
    line-height: 17px;
    letter-spacing: 0.1em;
    color:#B1B5C3 ;
    text-transform: uppercase;
}
.minimum{
    font-family: 'Nunito Sans';
    font-style: normal;
    font-weight: 500;
    font-size: 0.7em;
    line-height: 17px;
    letter-spacing: 0.1em;
    color:#3772FF; 
    padding-top: 5px;
}
.input{
    height: 50px;
    width: 100%;
    border: 1px solid #E6E8EC;
    box-shadow: 0px 10px 20px rgba(52, 72, 115, 0.12);
    border-radius: 4px;
    padding: 0em 1em;
    display: flex;
    align-items: center;
    color: #777E90;
    font-size: 1em;
    background: none;
    margin-top: 2em;
}
.rowInput{
    margin-top: 2em;
}
.rowInputHalfDiv{
    width: 48%;
}
.selectCoin{
    height: 50px;
    width: 100%;
    border: 1px solid #E6E8EC;
    box-shadow: 0px 10px 20px rgba(52, 72, 115, 0.12);
    border-radius: 4px;
    padding: 0em 1em;
    margin: 1em 0em;
    display: flex;
    align-items: center;
    cursor: pointer;
}
.selected{
    font-family: 'Nunito Sans';
    font-style: normal;
    font-weight: 600;
    font-size: 1em;
    line-height: 28px;
    color: #777E90; 
}
.whatAmount{
    padding-top: 2em;
}
.continueBtn{
    gap: 10px;
    border: none;
    height: 44px;     
    font-family: 'Nunito Sans';
    font-style: normal;
    font-weight: 600;
    font-size: 1em;
    line-height: 28px;
    text-align: center;
    color: #ffffff;
    cursor: pointer;
    width: 200px;
    background: #666DFF;
    border-radius: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.payicon{
   padding-right: 0.5em;
}
.proceedBtn{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 0em 3em;
    gap: 12px;
    height: 48px;
    background: #666DFF;
    border-radius: 90px; 
    align-self: center;
    color: white;
    border: none;
    font-family: 'Nunito Sans';
    height: 50px;
}
.dropdownToggle{
    display:flex;
    border-color:#777E90;
    justify-content:space-between;
    padding-left:10px;
    padding-right:0px;
    height: 50px;
    align-items:center;
    width: 100%;
    margin-top: 0.5em;
    border: 1px solid #E6E8EC;
    box-shadow: 0px 10px 20px rgba(52, 72, 115, 0.12);
    border-radius: 4px;
}
.dropBox{
    margin-top:1em;
    color:#FCFCFD;
    height:100px;
    overflow:auto;
    background:#111114 !important;
    width:100%
}
.inputDate{
    background: rgba(44, 45, 58, 0.05);
    border: 1px solid #B1B5C4 !important;
    backdrop-filter: blur(12px);
    border-radius:7px;
    height: 50px;
    width: 100%;
    padding: 0em 1em;
    color: #B1B5C4 ;
    margin-top: 1em;
    font-family: 'Nunito Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 0.95em;
    color-scheme: dark;
    display: flex;
}
.inputMain{
    border: none;
    width: 100%;
    height: 50px;
    background: none;
    font-family: 'Nunito Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 1em;
    line-height: 16px; 
    color: #B1B5C4 ;
    color-scheme: dark;
}
.dropDownrow{
    display: flex;
    align-items: center;
}
.inputToken{
    font-family: 'Nunito Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 0.9em;
    line-height: 24px;
    color:#777E91; 
    padding-right: 0.5em;
}
.borderLine{
    border: 1px solid #777E91;
    height: 15px;
    margin-right: 0.5em;
}
.instant{
     font-family: 'Nunito Sans';
     font-style: normal;
     font-weight: 900;
     font-size: 0.6em;
     line-height: 17px;
     letter-spacing: 0.1em;
     color: #FFFFFF;
}
.token{
    font-family: 'Nunito Sans';
    font-style: normal;
    font-weight: 800;
    font-size: 0.85em;
    line-height: 12px;
    text-transform: uppercase;
    color: #B1B5C4;
    padding-top: 2.5em;  
}
.helpBtn{
    width: 70%;
    border-radius: 8px;
    height: 45px;
    background: linear-gradient(90deg, #B88717 -5.35%, #FFD166 29.97%, #F5D281 81.19%, #C48E0E 107.68%);  
    border: none;
    height: 44px;    
    font-family: 'Nunito Sans';
    font-style: normal;
    font-weight: 500;
    font-size: 0.9em;
    line-height: 28px;
    text-align: center;  
    font-weight: 700;
}
@media screen and (max-width: 800px) {
    .box{
        padding:2em;
    }
}





