.cashNowForm {
  &__modal {
    &__hash {
      text-decoration: underline;
      margin-top: 10px;

      & img {
        margin-left: 10px;
      }
    }
    &__btns {
      display: flex;
      align-items: center;
      gap: 20px;
      flex-wrap: wrap;
      margin-top: 30px;

      & button {
        margin: 0;
      }
    }

    &__details {
      min-width: 400px;
      // width: 100%;
      @media screen and (max-width: 500px) {
        min-width: 100px;
      }

      & p {
        text-align: left;
        font-size: 17px;
        color: #fff;
        margin-bottom: 50px;
      }

      &__item {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 23px;
        font-size: 17px;
        gap: 10px;
      }
    }
  }
}

.sureSystem {
  &__title {
    color: #fff;
    font-size: 24px;
    font-style: normal;
    font-weight: 800;
    line-height: normal;
  }

  &__modal {
    max-width: 844px;
    height: auto;
    border-radius: 12px;
    background: #000;
    display: flex;
    margin: 20px auto;
    padding: 40px 5em;
    align-items: center;
    flex-direction: column;

    @media screen and (max-width: 650px) {
      padding: 40px 20px;
    }

    &__steps {
      width: 80%;
      display: flex;
      align-items: center;
      justify-content: space-evenly;
      img {
        width: 5%;
        height: 5%;

        @media screen and (max-width: 650px) {
          width: 7%;
          height: 7%;
        }
      }
      &__form {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-evenly;
      }
      &__p {
        width: 40px;
        height: 40px;
        display: flex;
        border-radius: 100px;
        align-items: center;
        color: #fff;
        justify-content: center;
        background: linear-gradient(
          90deg,
          #c9b2f9 0%,
          rgba(51, 0, 255, 0.99) 100%
        );

        @media screen and (max-width: 650px) {
          width: 30px;
          height: 30px;
        }
      }
      &__pp {
        width: 40px;
        height: 40px;
        display: flex;
        border-radius: 100px;
        align-items: center;
        color: white;
        justify-content: center;
        background: #d7d7d7;

        @media screen and (max-width: 650px) {
          width: 30px;
          height: 30px;
        }
      }
    }
    h1 {
      color: #fff;
      font-size: 32px;
      font-style: normal;
      font-weight: 700;
      margin: 1em 0;
      line-height: 40px; /* 125% */

      @media screen and (max-width: 650px) {
        font-size: 24px;
      }
    }
    P {
      color: #b1b5c3;
      font-size: 15px;
      font-style: normal;
      margin: 3em 0;
      font-weight: 600;
      line-height: 20px; /* 133.333% */
    }
  }
}
