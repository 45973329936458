.walletConnectMain {
  &__btn {
    height: 56px;
    background: linear-gradient(302.43deg, #9475d5 -2.77%, #3300ff 97.67%);
    border-radius: 4px;
    width: 100%;
    border: none;
    cursor: pointer;
    color: #fff;
    font-weight: 700;
  }
  &__btnAlt {
    height: 56px;
    background: #df2040;
    border-radius: 4px;
    width: 100%;
    border: none;
    cursor: pointer;
    color: #fff;
    font-weight: 700;
  }

  margin: 70px 0;
  &__wallet {
    max-width: 540px;
    margin: 0 auto;

    & h2 {
      font-weight: 700;
      font-size: 24px;
      margin-bottom: 30px;
      text-align: center;
    }

    & h3 {
      font-size: 14px;
      font-weight: 400;
      border-radius: 4px;
      border: 1px solid #f0f1ff;
      display: inline-block;
      padding: 5px;
    }

    &__disc {
      margin-bottom: 30px;
      & p {
        color: #fff;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        margin-bottom: 0;
      }
    }

    &__instr {
      background: #c9b2f9;
      padding: 20px;
      color: #30f;
      font-size: 15px;
      margin-top: 30px;
    }

    &__option {
      background-color: #161322;
      padding: 20px;
      display: flex;
      align-items: center;
      border-radius: 8px;
      margin-bottom: 30px;
      gap: 30px;
      justify-content: space-between;
      flex-wrap: wrap;

      & h4 {
        font-size: 20px;
        font-weight: 700;
      }

      & p {
        color: #fff;
        font-size: 12px;
        font-style: normal;
        font-weight: 600;
        max-width: 300px;
      }
    }

    &__list {
      display: flex;
      justify-content: center;
      flex-wrap: wrap;
      gap: 20px;
      margin-bottom: 20px;

      &__item {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding: 5px 0;
        width: 120px;
        height: 110px;
        background: #161322;
        border-radius: 8px;
        font-size: 14px;
        text-align: center;
        cursor: pointer;

        &:hover {
          background: linear-gradient(#161322, #161322) padding-box,
            linear-gradient(to right, #5158f6, #822df5, #f3a761) border-box;
          box-shadow: 0px 2px 20px -2px rgba(251, 221, 101, 0.05);
          border: 2px solid transparent;
        }

        & img {
          width: 45px;
          margin-bottom: 10px;
        }

        &.active {
          border-radius: 8px;
          background: linear-gradient(#161322, #161322) padding-box,
            linear-gradient(to right, #5158f6, #822df5, #f3a761) border-box;
          box-shadow: 0px 2px 20px -2px rgba(251, 221, 101, 0.05);
          border: 2px solid transparent;
        }
      }
    }
    &__btn {
      margin-top: 40px;
    }

    &__net {
      display: flex;
      flex-direction: column;

      & h3 {
        font-weight: 700;
        font-size: 24px;
        margin-bottom: 30px;
        text-align: center;
        margin-top: 80px;
      }

      & select {
        border-radius: 13px;
        background: #252527;
        border: none;
        width: 100%;
        height: 53px;
        color: #fff;
        text-align: center;
        font-size: 18px;
        font-weight: 700;

        &:focus {
          border: 1px solid #822df5;
          outline: none;
        }
      }

      & button {
        margin-top: 30px;
      }
    }
  }
  &__connect {
    max-width: 540px;
    width: 100%;
    display: flex;
    flex-direction: column;
    //   align-items: center;
    margin: 0 auto;

    & h2 {
      font-weight: 700;
      font-size: 24px;
      margin-bottom: 50px;
    }

    &__inputCon {
      width: 100%;
      // max-width: 460px;
      display: flex;
      flex-direction: column;
      gap: 10px;
      // align-items: center;
      margin: 0 auto;

      & input,
      select {
        width: 100%;
        border: 1px solid #777e91;
        border-radius: 5px;
        background-color: transparent;
        padding: 0 20px;
        height: 56px;
        margin-bottom: 20px;
        // text-align: center;
        // letter-spacing: 3px;
        font-size: 20px;
        color: #fff;

        &:disabled {
          color: #777e90;
        }
      }
    }
  }

  &__connected {
    max-width: 540px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0 auto;

    & h2 {
      font-weight: 700;
      font-size: 24px;
      margin-bottom: 40px;
    }

    & p {
      color: #aeaeae;
      margin-bottom: 16px;
    }

    &__address {
      width: 278px;
      height: 48px;
      display: flex;
      align-items: center;
      justify-content: center;
      background: #23262f;
      border-radius: 5px;
      font-size: 14px;
      margin-bottom: 50px;
    }
  }
}
