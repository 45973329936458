.parent{
    margin-top: 2em;
    background: #111114;
    padding: 2em;

}
.main{
    background: black;
    margin: 2em;
    padding: 2em;
}
.desc{
    font-family: 'Kanit';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #ffffff;
    padding-left: 2em; 
    cursor: pointer;
}
.alphabet{
    font-family: 'Kanit';
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 36px;
    display: flex;
    align-items: center;
    text-align: center;
    text-transform: capitalize;
    color: #ffffff;
    padding-top: 2em;
}
.row{
    width: 100%;
    display: flex;
    margin-top: 2em;
    flex-wrap: wrap;
}
.nameBox{
    width: 25%;
    padding-top: 1em;
    font-family: 'Kanit';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    align-items: center;
    text-transform: capitalize;   
}
.border{
    border: 1px solid gray;
    width: 100%;
    border-style: dashed;
    margin-top: 2em;
}
@media screen and (max-width: 800px) {
    .nameBox{
        width: 50%;
    }
}